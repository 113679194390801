import React from 'react';
import { BLANK, NO_OPENER } from '@constants';
import { useSourcedLink } from '@hooks';
import { getKeyValue } from '@utils';

import Logo from '../../../assets/logo.inline.svg';
import * as styles from './Footer.module.scss';

const Footer = (props) => {
  const {
    homepage, primaryFooterLinks, secondaryFooterLinks, copyright,
    paidForDisclaimer, socialLinks, footerCallToAction,
  } = props;
  const hpLink = homepage ? homepage[0] : {};
  const [makeSourcedLink] = useSourcedLink();

  return (
    <footer className={styles.footer}>
      <div className={styles.footer__content}>
        <div className={styles.footer__contentrow}>
          {(primaryFooterLinks || secondaryFooterLinks) && (
            <ul className={styles.footer__footerlinks}>
              <li className={styles.footer__logo}>
                <Logo aria-label={hpLink.key} />
              </li>
              {primaryFooterLinks && (
              <li key="primaryfooter">
                <ul className={styles.footer__primarylinks}>
                  {primaryFooterLinks.map((link) => {
                    const { externalLink } = link;
                    const { path } = getKeyValue(link, 'internalPageLink') || {};
                    const { url } = getKeyValue(link, 'internalFileLink.file') || {};
                    const linkProps = {
                      href: makeSourcedLink(path || url || externalLink),
                    };
                    if (externalLink) {
                      linkProps.target = BLANK;
                    }
                    return (
                      <li key={link.id}>
                        <a {...linkProps} className={styles.footer__primarylink}>{link.label}</a>
                      </li>
                    );
                  })}
                </ul>
              </li>
              )}
              {secondaryFooterLinks && (
                <li key="secondaryfooter">
                  <ul className={styles.footer__secondarylinks}>
                    {secondaryFooterLinks.map((link) => {
                      const { externalLink } = link;
                      const { path } = getKeyValue(link, 'internalPageLink') || {};
                      const { url } = getKeyValue(link, 'internalFileLink.file') || {};
                      const linkProps = {
                        href: makeSourcedLink(path || url || externalLink),
                      };
                      if (externalLink) {
                        linkProps.target = BLANK;
                      }
                      return (
                        <li key={link.id}>
                          <a
                            {...linkProps}
                            className={styles.footer__secondarylink}
                          >
                            {link.label}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              )}
            </ul>
          )}
          {(socialLinks || footerCallToAction) && (
            <ul className={styles.footer__lastcol}>
              {footerCallToAction && (
                <li key={footerCallToAction.id}>
                  <a
                    href={makeSourcedLink(footerCallToAction.externalLink)}
                    target={BLANK}
                    rel={NO_OPENER}
                    className={styles.footer__cta}
                  >
                    {footerCallToAction.label}
                  </a>
                </li>
              )}
              {socialLinks && (
                <ul className={styles.footer__sociallinks}>
                  {socialLinks.map((social) => (
                    <li key={social.value} className={styles.footer__sociallinkitem}>
                      <a
                        className={`${styles.footer__sociallink} scdicon-- scdicon--${(social.key).toLowerCase()}`}
                        href={makeSourcedLink(social.value)}
                        target={BLANK}
                        rel={NO_OPENER}
                      >
                        {social.key}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
              <li
                key={paidForDisclaimer}
                className={styles.footer__disclaimer}
              >
                {paidForDisclaimer}
              </li>
              <li
                className={styles.footer__copyright}
                key={styles.footer__copyright}
              >
                {copyright}
              </li>
            </ul>
          )}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
