import React from 'react';
import cx from 'classnames';
import { BasicRichText } from '@molecules';
import { useSourcedLink } from '@hooks';
import { appendQueryString, capitalizeFirstLetter, getExpressLink } from '@utils';
import {
  DARK_BLUE, LC_WHITE, LC_BASE, XSMALL, LEFT, BLANK, NO_OPENER,
} from '@constants';

import * as styles from './DonationSection.module.scss';

const DonationSection = (props) => {
  const {
    sectionTheme = 'dark', sectionTitle, summaryContent, firstSection, contentReferences,
    donationDisclaimer, previousSection, previousSectionDonation, previousSectionSignup,
  } = props;
  const themeAppend = capitalizeFirstLetter(sectionTheme.replace(/\s/g, ''));
  const donationContent = contentReferences && contentReferences[0];
  const { desktopDonationButtons, mobileDonationButtons, donationLink } = donationContent || {};
  const buttonCount = desktopDonationButtons ? desktopDonationButtons.length : 0;
  const mobButtonCount = mobileDonationButtons ? mobileDonationButtons.length : 0;
  const { theme: prevSectionTheme } = previousSection || {};
  const prevThemeAppend = prevSectionTheme ? capitalizeFirstLetter(prevSectionTheme.replace(/\s/g, '')) : themeAppend;
  const [makeSourcedLink] = useSourcedLink();
  const richTextTheme = sectionTheme === DARK_BLUE ? LC_WHITE : LC_BASE;

  return (
    <section
      className={cx(styles.donationsection,
        styles[`donationsection${prevThemeAppend}`], {
          [styles.donationsectionFirstSection]: firstSection,
          [styles.donationsectionPreviousDonation]: (
            previousSectionDonation || previousSectionSignup),
        })}
    >
      <div
        className={cx(styles.donationsection__wrapper,
          styles[`donationsection__wrapper${themeAppend}`])}
      >
        <div
          className={cx(styles.donationsection__content, {
            [styles.donationsection__contentFirstSection]: firstSection,
          })}
        >
          {sectionTitle && (
            <h2 className={cx(styles.donationsection__heading)}>
              {sectionTitle}
            </h2>
          )}
          {summaryContent && (
            <BasicRichText content={summaryContent} color={richTextTheme} />
          )}
        </div>
        <div className={styles.donationsection__ask}>
          {desktopDonationButtons && (
            <ul className={styles.donationsection__buttons}>
              {desktopDonationButtons.map((amount) => {
                const expLink = getExpressLink({ donationLink, amount });
                return (
                  <li
                    className={cx(styles.donationsection__button, styles[`donationsection__button${buttonCount}`])}
                    key={amount.key}
                  >
                    <a
                      href={makeSourcedLink(appendQueryString(expLink, `amount=${amount.value}`))}
                      target={BLANK}
                      rel={NO_OPENER}
                    >
                      {amount.key}
                    </a>
                  </li>
                );
              })}
            </ul>
          )}
          {mobileDonationButtons && donationLink && (
            <ul className={cx(styles.donationsection__buttons,
              styles.donationsection__buttonsMobile)}
            >
              {mobileDonationButtons.map((amount) => {
                const expLink = getExpressLink({ donationLink, amount });
                return (
                  <li
                    className={cx(styles.donationsection__button, styles[`donationsection__button${mobButtonCount}`])}
                    key={`${amount.key}-mobile`}
                  >
                    <a
                      href={makeSourcedLink(appendQueryString(expLink, `amount=${amount.value}`))}
                      target={BLANK}
                      rel={NO_OPENER}
                    >
                      {amount.key}
                    </a>
                  </li>
                );
              })}
            </ul>
          )}
          {(donationDisclaimer) && (
            <BasicRichText
              content={donationDisclaimer}
              size={XSMALL}
              color={richTextTheme}
              align={LEFT}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default DonationSection;
