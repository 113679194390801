import React from 'react';

const defaultState = {
  sticky: false,
  active: false,
  toggleModal: () => {},
  toggleSticky: () => {},
};

const ModalContext = React.createContext(defaultState);

class ModalProvider extends React.Component {
  constructor() {
    super();

    this.state = {
      active: false,
      sticky: false,
    };

    this.toggleModal = () => {
      const { active } = this.state;
      const newActive = !active;
      this.setState({ active: newActive });
    };

    this.toggleSticky = (newState) => {
      this.setState({ sticky: newState });
    };
  }

  render() {
    const { children } = this.props;
    const { active, sticky } = this.state;
    return (
      <ModalContext.Provider
        value={{
          active,
          sticky,
          toggleModal: this.toggleModal,
          toggleSticky: this.toggleSticky,
        }}
      >
        {children}
      </ModalContext.Provider>
    );
  }
}
export default ModalContext;
export { ModalProvider };
