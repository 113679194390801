import React, {
  useEffect, useContext,
} from 'react';
import cx from 'classnames';
import { useInView } from 'react-intersection-observer';
import Img from 'gatsby-image';
import { NEWS, PLAN } from '@constants';

import ModalContext from '../../../context/ModalContext';
import * as styles from './BasicDarkHero.module.scss';

const BasicDarkHero = ({
  title, eyebrow, byline, releaseDate, heroImage, publisher, pageType,
}) => {
  const [ref,
    inView] = useInView({ triggerOnce: true, rootMargin: '-100px 0px' });
  const noTitleMargin = !byline && !releaseDate && !publisher;
  const noImage = !heroImage || !heroImage.fluid;
  const {
    toggleSticky,
  } = useContext(ModalContext);
  const [titleRef, titleInview] = useInView({ triggerOnce: false, rootMargin: '100px 0px', initialInView: true });

  useEffect(() => {
    toggleSticky(!titleInview);
  }, [titleInview, toggleSticky]);

  return (
    <div
      className={cx(styles.basicDarkHero, {
        [styles.basicDarkHeroHasImage]: !noImage,
        [styles.basicDarkHeroNoImage]: noImage,
      })}
    >
      <div className={styles.basicDarkHero__inner}>
        <div
          className={cx(styles.basicDarkHero__contentWrapper, {
            [styles.basicDarkHero__contentWrapperHasImage]: !noImage,
            [styles.basicDarkHero__contentWrapperNoImage]: noImage,
          })}
          ref={ref}
        >
          <h1
            className={cx(styles.basicDarkHero__title, styles.basicDarkHero__titleAnimate, {
              [styles.basicDarkHero__titleAnimateInView]: inView,
              [styles.basicDarkHero__titleNoMargin]: noTitleMargin,
              [styles.basicDarkHero__titleHasImage]: !noImage,
              [styles.basicDarkHero__titleNoImage]: noImage,
              [styles.basicDarkHero__titleNews]: pageType === NEWS,
              [styles.basicDarkHero__titlePlan]: pageType === PLAN,
            })}
            ref={titleRef}
          >
            {eyebrow && (
              <span className={styles.basicDarkHero__eyebrow}>
                {eyebrow}
                {' '}
              </span>
            )}
            {title}
          </h1>
          {(byline || releaseDate || publisher) && (
            <h2
              className={cx(styles.basicDarkHero__details, {
                [styles.basicDarkHero__detailsInView]: inView,
              })}
            >
              {byline && (
                <span
                  className={cx(styles.basicDarkHero__author, {
                    [styles.basicDarkHero__authorAddBorder]: (!!releaseDate || !!publisher),
                  })}
                >
                  {byline}
                </span>
              )}
              {publisher && (
                <span
                  className={cx(styles.basicDarkHero__author, {
                    [styles.basicDarkHero__authorAddBorder]: (!!releaseDate || !!byline),
                  })}
                >
                  {publisher}
                </span>
              )}
              {releaseDate && (
                <span
                  className={cx(styles.basicDarkHero__date, {
                    [styles.basicDarkHero__dateAddBorder]: (!!publisher || !!byline),
                  })}
                >
                  {releaseDate}
                </span>
              )}
            </h2>
          )}
        </div>
        {heroImage && heroImage.file && (
          <div className={styles.basicDarkHero__image} style={{ backgroundImage: `url(${heroImage.file.url})` }} />
        )}
        {heroImage && heroImage.fluid && (
          <Img
            fluid={heroImage.fluid}
            className={styles.basicDarkHero__mobimage}
          />
        )}
      </div>
    </div>
  );
};

export default BasicDarkHero;
