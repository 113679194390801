import React, {
  useEffect, useState, useRef, useContext,
} from 'react';
import cx from 'classnames';
import { useInView } from 'react-intersection-observer';
import { DARK, LC_WHITE } from '@constants';
import { BasicRichText, HeroDonationAsk } from '@molecules';
import { FormWrapper, ModalVideo } from '@organisms';
import { getKeyValue } from '@utils';

import ModalContext from '../../../context/ModalContext';
import Pause from '../../../assets/pause.inline.svg';
import Play from '../../../assets/play.inline.svg';
import * as styles from './HomepageHero.module.scss';

const HomepageHero = ({
  title, subheading, heroMedia, heroAction, donationDisclaimer, mobileDisclaimer,
  heroPlayVideoText, heroImagePlaceholder,
}) => {
  const [isPlaying, setIsPlaying] = useState(true);
  const { contentType, url: videoUrl } = getKeyValue(heroMedia, 'imageOrCustomThumbnail.file') || {};
  const { url: imgUrl } = getKeyValue(heroImagePlaceholder, 'file') || {};
  const { description: imgAlt } = heroImagePlaceholder || {};
  const isVideo = contentType && contentType.includes('video');
  const videoRef = useRef();
  const { mediaUid } = heroMedia || {};
  const splitTitle = title && title.replace(/\u2028/g, '').split('\n');
  const splitTitleLength = splitTitle && splitTitle.length;
  const {
    active, toggleModal, toggleSticky,
  } = useContext(ModalContext);
  const [buttonsRef, buttonsInView] = useInView({ triggerOnce: false, rootMargin: '100px 0px', initialInView: true });

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    const step = isPlaying ? 'play' : 'pause';
    videoRef.current[step]();
  }, [isPlaying]);

  useEffect(() => {
    setIsPlaying(!active);
  }, [active]);

  useEffect(() => {
    toggleSticky(!buttonsInView);
  }, [buttonsInView, toggleSticky]);

  return (
    <React.Fragment>
      <div className={styles.homepageHero}>
        <div className={styles.homepageHero__inner}>
          <div className={styles.homepageHero__contentWrapper}>
            <h1
              className={cx(styles.homepageHero__title)}
            >
              {splitTitle.map((t, i) => (
                <span
                    // eslint-disable-next-line react/no-array-index-key
                  key={`${t}-${i}`}
                  className={cx({
                    [styles.homepageHero__titleLast]: i === (splitTitleLength - 1),
                    [styles.homepageHero__titleBasic]: i !== (splitTitleLength - 1),
                  })}
                >
                  {t}
                </span>
              ))}
            </h1>
            <div className={styles.homepageHero__actsub}>
              {subheading && (
                <div className={styles.homepageHero__sub}>
                  <BasicRichText content={subheading} color={LC_WHITE} />
                </div>
              )}
              <div className={styles.homepageHero__action}>
                <FormWrapper
                  {...heroAction}
                  formTheme={DARK}
                  donationDisclaimer={donationDisclaimer}
                  mobileDisclaimer={mobileDisclaimer}
                  formComponentType="heroAction"
                />
                {!heroAction.formName && (
                  <HeroDonationAsk
                    {...heroAction}
                    formTheme={DARK}
                    donationDisclaimer={donationDisclaimer}
                    mobileDisclaimer={mobileDisclaimer}
                    formComponentType="heroAction"
                  />
                )}
              </div>
            </div>
          </div>
          {isVideo ? (
            <div className={styles.homepageHero__videowrapper}>
              {videoUrl ? (
                <React.Fragment>
                  <video
                    src={videoUrl}
                    className={styles.homepageHero__video}
                    autoPlay
                    playsInline
                    muted
                    loop
                    preload="auto"
                    poster={imgUrl}
                    ref={videoRef}
                  />
                  <div className={styles.homepageHero__buttons} ref={buttonsRef}>
                    <button
                      type="button"
                      className={styles.homepageHero__button}
                      onClick={() => toggleModal()}
                    >
                      {heroPlayVideoText || 'play video'}
                    </button>
                    <button
                      type="button"
                      className={styles.homepageHero__ambbutton}
                      onClick={() => setIsPlaying(!isPlaying)}
                    >
                      {isPlaying ? <Pause /> : <Play />}
                    </button>
                  </div>
                  <div className={styles.homepageHero__videograd} />
                </React.Fragment>
              ) : (
                <React.Fragment>
                <img
                  src={imgUrl}
                  className={styles.homepageHero__video}
                  alt={imgAlt}
                />
                <div className={cx(styles.homepageHero__videograd, styles.homepageHero__videogradImage)} />
                </React.Fragment>
              )}
            </div>) : (
            <div className={styles.homepageHero__videowrapper}>
              <img
                src={imgUrl}
                className={styles.homepageHero__video}
                alt={imgAlt}
              />
              <div className={cx(styles.homepageHero__videograd, styles.homepageHero__videogradImage)} />
            </div>
          )}
        </div>
      </div>
      <ModalVideo videoYoutubeId={mediaUid} />
    </React.Fragment>
  );
};

export default HomepageHero;
