import React from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { useSourcedLink } from '@hooks';
import cx from 'classnames';
import { capitalizeFirstLetter } from '@utils';

import rtoptions from './rtoptions';
import * as styles from './BasicRichText.module.scss';

const BasicRichText = ({
  id, content, size = 'medium', color = 'base', align = 'left',
  alignMobile = 'left', textWidth = 'wide', pageType, mobileDisclaimer,
  donationDisclaimer,
}) => {
  const [makeSourcedLink] = useSourcedLink();
  const options = rtoptions({
    color, size, pageType, mobileDisclaimer, donationDisclaimer, makeSourcedLink, id,
  });

  return (
    <div
      key={id}
      className={cx(styles.basicRichText, {
        [styles[`basicRichText${capitalizeFirstLetter(align)}`]]: align,
        [styles[`basicRichText${capitalizeFirstLetter(align)}mobile`]]: alignMobile,
        [styles[`basicRichText${capitalizeFirstLetter(textWidth)}`]]: textWidth,
      })}
    >
      {renderRichText(content, options)}
    </div>
  );
};

export default BasicRichText;
