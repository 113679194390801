/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import cx from 'classnames';
import { Link } from 'gatsby';
import { useSourcedLink } from '@hooks';

import Arrow from '../../../assets/arrow.inline.svg';
import * as styles from './NewsPagination.module.scss';

const NewsPagination = (props) => {
  const {
    itemPageCount, itemPage, slug,
  } = props;
  const [makeSourcedLink] = useSourcedLink();

  const items = [];
  for (let i = 0; i < itemPageCount; i += 1) {
    const key = `newspage-${i}`;
    if (i === 0 && i !== itemPage) {
      items.push(
        <li key="previous">
          <Link
            aria-label={`Go to previous page (${itemPage})`}
            to={makeSourcedLink(itemPage - 1 > 0 ? `${slug}/${itemPage - 1}` : slug)}
            className={cx(
              styles.newspagination__button, styles.newspagination__buttonPrevious,
            )}
          >
            <Arrow />
          </Link>
        </li>,
      );
    }
    items.push(
      <li
        key={key}
      >
        {i === itemPage ? (
          <a
            // eslint-disable-next-line no-script-url
            href="javascript:void(0)"
            className={cx(
              styles.newspagination__item, styles.newspagination__itemSelected,
            )}
            aria-current="page"
            aria-label={`Page ${i + 1}`}
          >
            {i + 1}
          </a>
        ) : (
          <Link
            to={makeSourcedLink(i ? `${slug}/${i}` : slug)}
            className={cx(
              styles.newspagination__item, styles.newspagination__itemSelectable,
            )}
            aria-label={`Page ${i + 1}`}
          >
            {i + 1}
          </Link>
        )}
      </li>,
    );
    if (i === (itemPageCount - 1) && i !== itemPage) {
      items.push(
        <li key="next">
          <Link
            aria-label={`Go to next page (${itemPage + 1})`}
            to={makeSourcedLink(itemPage + 1 > 0 ? `${slug}/${itemPage + 1}` : slug)}
            className={cx(
              styles.newspagination__button, styles.newspagination__buttonNext,
            )}
          >
            <Arrow />
          </Link>
        </li>,
      );
    }
  }

  return (
    <nav aria-label="Pagination">
      <ul className={styles.newspagination}>
        {items}
      </ul>
    </nav>
  );
};

export default NewsPagination;
