import React from 'react';
import cx from 'classnames';
import Img from 'gatsby-image';
import { Cta } from '@atoms';
import { BLANK } from '@constants';
import { BasicRichText } from '@molecules';
import { capitalizeFirstLetter } from '@utils';

import * as styles from './BioSection.module.scss';

const BioSection = (props) => {
  const {
    sectionTheme, sectionTitle, readMoreText, internalReadMoreLink, externalReadMoreLink,
    firstSection, summaryContent, contentReferences, previousSectionDonation, previousSectionSignup,
  } = props;
  const themeAppend = capitalizeFirstLetter(sectionTheme.replace(/\s/g, ''));
  const { path } = internalReadMoreLink;
  const linkProps = {
    href: path || externalReadMoreLink,
  };
  if (externalReadMoreLink) {
    linkProps.target = BLANK;
  }
  const media = contentReferences[0];

  return (
    <section
      className={cx(styles.biosection,
        styles[`biosection${themeAppend}`], {
          [styles.biosectionFirstSection]: firstSection,
          [styles.biosectionPreviousDonation]: (
            previousSectionDonation || previousSectionSignup),
        })}
    >
      <div className={styles.biosection__wrapper}>
        <div
          className={cx(styles.biosection__content, {
            [styles.biosection__contentFirstSection]: firstSection,
          })}
        >
          {sectionTitle && (
            <h2 className={cx(styles.biosection__heading, styles.biosection__headingDesktop)}>
              {sectionTitle}
            </h2>
          )}
          {summaryContent && (
          <BasicRichText content={summaryContent} />
          )}
          {linkProps.href && (
          <div className={styles.biosection__ctawrapper}>
            <Cta {...linkProps}>{readMoreText}</Cta>
          </div>
          )}
        </div>
        {media && (
        <div className={styles.biosection__imagewrapper}>
          {sectionTitle && (
            <h2
              className={cx(styles.biosection__heading, styles.biosection__headingMobile)}
            >
              {sectionTitle}
            </h2>
          )}
          <Img
            alt={media.imageOrCustomThumbnail}
            fluid={media.imageOrCustomThumbnail.fluid}
            className={cx(styles.biosection__image, {
              [styles.biosection__imageFirstSection]: firstSection,
            })}
          />
        </div>
        )}
      </div>
    </section>
  );
};

export default BioSection;
