/* Form Themes */
export const DARK = 'dark';
export const LIGHT = 'light';

export const BLANK = '_blank';
export const NO_OPENER = 'noopener';
export const NO_REFERRER = 'noreferrer';
export const REL = `${NO_OPENER} ${NO_REFERRER}`;
export const HTTP = 'http';
export const TRANSPARENT = 'transparent';
export const DISMISS_ALERT_BAR = 'dismiss_alert_bar';
export const DISMISS_DONATE_BAR = 'dismiss_donate_bar';

/* Text Sizing */
export const LARGE = 'large';
export const MEDIUM = 'medium';
export const SMALL = 'small';
export const XSMALL = 'xsmall';

/* Alignment */
export const LEFT = 'left';
export const RIGHT = 'right';
export const CENTER = 'center';

/* Column Width */
export const SINGLE_COL = 'singlecol';

/* Page Types */
export const CONTENT_DETAIL = 'contentDetail';
export const HOMEPAGE = 'homepage';
export const NEWS_ITEM = 'newsItem';

/* Homepage Section Types */
export const BIO = 'Bio';
export const NEWS = 'News';
export const TAKE_ACTION = 'Take Action';
export const PLAN = 'Plan';
export const DONATION = 'Donation';
export const SIGNUP = 'Sign Up';
export const ENDORSEMENT = 'Endorsement';
export const HP_TYPE = {
  BIO,
  NEWS,
  TAKE_ACTION,
  SIGNUP,
  ENDORSEMENT,
};

/* Section Themes */
export const BASE = 'Base';
export const LC_BASE = BASE.toLowerCase();
export const LIGHT_BLUE = 'Light Blue';
export const LC_LIGHT_BLUE = LIGHT_BLUE.toLowerCase();
export const DARK_BLUE = 'Dark Blue';
export const LC_DARK_BLUE = DARK_BLUE.toLowerCase();
export const WHITE = 'White';
export const LC_WHITE = WHITE.toLowerCase();
export const BLACK = 'black';
export const LC_BLACK = BLACK.toLowerCase();

/* Homepage Section Themes */
export const HP_THEME = {
  LIGHT_BLUE,
  DARK_BLUE,
  WHITE,
};
