/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import Img from 'gatsby-image';
import cx from 'classnames';
import { SMALL } from '@constants';
import { BasicRichText } from '@molecules';

import * as styles from './ImageCollage.module.scss';

const ImageCollage = ({
  caption, imageLayout, images,
}) => {
  const imgIndex = imageLayout === '2 Columns' ? 1 : 0;
  return (
    <figure className={cx(styles.imagecollage, {
      [styles.imagecollage2Col]: imageLayout === '2 Columns',
      [styles.imagecollage1Col]: imageLayout === '1 Column',
    })}
    >
      {images && images.map((image, i) => (
        <div
          className={cx(styles.imagecollage__imagewrap, {
            [styles.imagecollage__imagewrap2Col]: imageLayout === '2 Columns',
            [styles.imagecollage__imagewrap1Col]: imageLayout === '1 Column',
          })}
          key={image.contentful_id}
        >
          <Img fluid={image.fluid} />
          {i === imgIndex && caption && (
            <figcaption className={cx(styles.imagecollage__caption, {
              [styles.imagecollage__caption1Col]: imageLayout === '1 Column',
            })}
            >
              <BasicRichText content={caption} size={SMALL} />
            </figcaption>
          )}
        </div>
      ))}
    </figure>
  );
};

export default ImageCollage;
