/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef, useEffect } from 'react';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import Img from 'gatsby-image';
import { LC_BLACK, SMALL } from '@constants';
import { BasicRichText } from '@molecules';

import Play from '../../../assets/play.inline.svg';
import * as styles from './Video.module.scss';

const Video = ({
  caption, title, videoId, customThumbnail, iframeSrc, titleLevel,
}) => {
  const [isIframeLoading, setIsIframeLoading] = useState(false);
  const [videoPlayer, setVideoPlayer] = useState(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isInViewport, setIsInViewport] = useState(false);
  const videoRef = useRef();

  let youtubeIframeAPI;

  const iframeAPIReady = () => new Promise((resolve) => {
    if (window.YT && window.YT.Player && window.YT.Player instanceof Function) {
      resolve(window.YT);
      setIsIframeLoading(true);
      return;
    }

    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    const previous = window.onYouTubeIframeAPIReady;

    window.onYouTubeIframeAPIReady = () => {
      setIsIframeLoading(true);
      if (previous) {
        previous();
      }
      resolve(window.YT);
    };
  });

  function onVideoClick() {
    youtubeIframeAPI = iframeAPIReady();
    youtubeIframeAPI.then((YT) => {
      // eslint-disable-next-line no-unused-vars
      const player = new YT.Player(videoId, {
        videoId,
        playerVars: {
          modestBranding: 1,
          rel: 0,
        },
        events: {
          onReady: (e) => {
            e.target.playVideo();
          },
          onStateChange: (e) => {
            if (e.data === 1 || e.data === 3) {
              setIsVideoPlaying(true);
            } else {
              setIsVideoPlaying(false);
            }
          },
        },
      });
      setVideoPlayer(player);
    });
  }

  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useScrollPosition(
      ({ currPos }) => {
        const newIsInViewport = currPos.y > -200 && window && currPos.y <= window.innerHeight;
        if (isInViewport !== newIsInViewport) {
          setIsInViewport(newIsInViewport);
        }
      }, [isInViewport], videoRef, false, 300,
    );
  }

  useEffect(() => {
    if (videoPlayer && isVideoPlaying && !isInViewport && videoPlayer.pauseVideo) {
      videoPlayer.pauseVideo();
    }
  }, [videoPlayer, isVideoPlaying, isInViewport]);

  const thumbnailProps = {
    onClick: onVideoClick,
    src: `https://img.youtube.com/vi/${videoId}/0.jpg`,
  };

  const Header = titleLevel ? `h${titleLevel}` : 'h3';

  return (
    <figure className={styles.video__container} ref={videoRef}>
      {!isIframeLoading && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <div
          className={styles.video__imagewrapper}
          onClick={onVideoClick}
          role="button"
          tabIndex="-1"
        >
          {!customThumbnail ? (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <img
              {...thumbnailProps}
              onClick={onVideoClick}
              className={styles.video__image}
              alt={`thumbnail for video: ${title}`}
            />
          ) : (
            <Img
              fluid={customThumbnail.fluid}
              className={styles.video__image}
            />
          )}
          <button
            onClick={onVideoClick}
            aria-label={`play ${title}`}
            type="button"
            className={styles.video__playbutton}
          >
            <Play />
          </button>
        </div>
      )}
      {(isIframeLoading) && (
        <span className={styles.video__containerinline}>
          {videoId && !iframeSrc && <span id={videoId} />}
        </span>
      )}
      {(caption || title) && (
        <React.Fragment>
          <figcaption className={styles.video__caption} aria-hidden>
            {title && (
            <Header className={styles.video__title}>
              {title}
              {title && caption ? ' | ' : ''}
            </Header>
            )}
            {caption && (
              <BasicRichText content={caption} size={SMALL} color={LC_BLACK} />
            )}
          </figcaption>
        </React.Fragment>
      )}
    </figure>
  );
};

export default Video;
