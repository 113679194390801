// extracted by mini-css-extract-plugin
export var endorsementsection = "EndorsementSection-module--endorsementsection--3PTXF";
export var endorsementsectionDarkBlue = "EndorsementSection-module--endorsementsection--darkBlue--6GiyR";
export var endorsementsectionFirstSection = "EndorsementSection-module--endorsementsection--firstSection--IZSX4";
export var endorsementsectionLightBlue = "EndorsementSection-module--endorsementsection--lightBlue--eujUM";
export var endorsementsectionPreviousDonation = "EndorsementSection-module--endorsementsection--previousDonation--LLKIm";
export var endorsementsectionWhite = "EndorsementSection-module--endorsementsection--white--JCW9p";
export var endorsementsectionWhite__imagewrapLogo = "EndorsementSection-module--endorsementsection--white__imagewrap--logo--Y8BVp";
export var endorsementsection__content = "EndorsementSection-module--endorsementsection__content--oxoG-";
export var endorsementsection__contentFirstSection = "EndorsementSection-module--endorsementsection__content--firstSection--sRo7g";
export var endorsementsection__ctawrapper = "EndorsementSection-module--endorsementsection__ctawrapper--B+-WJ";
export var endorsementsection__ctawrapperMobile = "EndorsementSection-module--endorsementsection__ctawrapper--mobile--Xdj85";
export var endorsementsection__endheading = "EndorsementSection-module--endorsementsection__endheading--h9qyc";
export var endorsementsection__endlink = "EndorsementSection-module--endorsementsection__endlink--tEcYo";
export var endorsementsection__endlinkLink = "EndorsementSection-module--endorsementsection__endlink--link--3ZVuw";
export var endorsementsection__heading = "EndorsementSection-module--endorsementsection__heading--PGh7c";
export var endorsementsection__image = "EndorsementSection-module--endorsementsection__image--d3fHb";
export var endorsementsection__imageLogo = "EndorsementSection-module--endorsementsection__image--logo--b3Xna";
export var endorsementsection__imagewrap = "EndorsementSection-module--endorsementsection__imagewrap--aOfE6";
export var endorsementsection__imagewrapLogo = "EndorsementSection-module--endorsementsection__imagewrap--logo--UUMBu";
export var endorsementsection__item = "EndorsementSection-module--endorsementsection__item--z1H00";
export var endorsementsection__listing = "EndorsementSection-module--endorsementsection__listing--mR931";
export var endorsementsection__text = "EndorsementSection-module--endorsementsection__text--+R0DN";
export var endorsementsection__wrapper = "EndorsementSection-module--endorsementsection__wrapper--M0yvP";
export var fadeInAnimation = "EndorsementSection-module--fadeInAnimation--rfMO2";
export var fadeOutAnimation = "EndorsementSection-module--fadeOutAnimation--fH232";
export var slideIn = "EndorsementSection-module--slideIn--1VkLD";
export var slideInMobileNav = "EndorsementSection-module--slideInMobileNav--5wrye";
export var slideInNav = "EndorsementSection-module--slideInNav--mi32d";
export var slideInTop = "EndorsementSection-module--slideInTop--DRkI+";
export var slideOut = "EndorsementSection-module--slideOut--kputq";
export var slideOutTop = "EndorsementSection-module--slideOutTop--xSeth";