import React from 'react';
import cx from 'classnames';
import {
  LC_BLACK, NEWS_ITEM, BLANK, HTTP,
} from '@constants';
import { BasicRichText } from '@molecules';

import * as styles from './NewsItem.module.scss';

const NewsItem = (props) => {
  const {
    itemKey, title, category, newsLink, titleEyebrow, summary,
    publicationDate, publisher,
  } = props;

  const newsLinkProps = {};
  if (newsLink.startsWith(HTTP)) {
    newsLinkProps.target = BLANK;
  }

  return (
    <li
      id={itemKey}
      className={styles.newsitem}
    >
      <div className={styles.newsitem__content}>
        {title && (
        <h3 className={styles.newsitem__title}>
          {category && (
          <span className={styles.newsitem__eyebrow}>
            {category}
          </span>
          )}
          <a className={cx(styles.newsitem__heading)} href={newsLink} {...newsLinkProps}>
            {titleEyebrow ? `${titleEyebrow} ${title}` : title}
          </a>
        </h3>
        )}
        {(publicationDate || publisher) && (
        <p className={styles.newsitem__meta}>
          {publisher && (
            <span>{publisher}</span>
          )}
          {publisher && publicationDate && (
            <span className={styles.newsitem__dash}> | </span>
          )}
          {publicationDate && (
            <span>{publicationDate}</span>
          )}
        </p>
        )}
        {summary && (
        <div className={styles.newsitem__summary}>
          <BasicRichText
            content={summary}
            color={LC_BLACK}
            pageType={NEWS_ITEM}
          />
        </div>
        )}
      </div>
    </li>
  );
};

export default NewsItem;
