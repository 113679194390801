// extracted by mini-css-extract-plugin
export var basicDarkHero = "BasicDarkHero-module--basicDarkHero--r0RKZ";
export var basicDarkHeroHasImage = "BasicDarkHero-module--basicDarkHero--hasImage--E4zdn";
export var basicDarkHeroNoImage = "BasicDarkHero-module--basicDarkHero--noImage--SD-W6";
export var basicDarkHero__author = "BasicDarkHero-module--basicDarkHero__author--oyddI";
export var basicDarkHero__authorAddBorder = "BasicDarkHero-module--basicDarkHero__author--addBorder--Q00Yy";
export var basicDarkHero__contentWrapper = "BasicDarkHero-module--basicDarkHero__contentWrapper--jTuEp";
export var basicDarkHero__contentWrapperHasImage = "BasicDarkHero-module--basicDarkHero__contentWrapper--hasImage--Yd6l-";
export var basicDarkHero__contentWrapperNoImage = "BasicDarkHero-module--basicDarkHero__contentWrapper--noImage--OY4B5";
export var basicDarkHero__contentWrapperNoImagePlan = "BasicDarkHero-module--basicDarkHero__contentWrapper--noImage--plan--3xnpj";
export var basicDarkHero__date = "BasicDarkHero-module--basicDarkHero__date--rMzvp";
export var basicDarkHero__dateAddBorder = "BasicDarkHero-module--basicDarkHero__date--addBorder--XNeYW";
export var basicDarkHero__details = "BasicDarkHero-module--basicDarkHero__details--dM32C";
export var basicDarkHero__detailsInView = "BasicDarkHero-module--basicDarkHero__details--inView--LZuvZ";
export var basicDarkHero__eyebrow = "BasicDarkHero-module--basicDarkHero__eyebrow--yZ0WG";
export var basicDarkHero__image = "BasicDarkHero-module--basicDarkHero__image--pOgKS";
export var basicDarkHero__inner = "BasicDarkHero-module--basicDarkHero__inner--NwKSB";
export var basicDarkHero__mobimage = "BasicDarkHero-module--basicDarkHero__mobimage--bmEud";
export var basicDarkHero__title = "BasicDarkHero-module--basicDarkHero__title--qw8hL";
export var basicDarkHero__titleAnimate = "BasicDarkHero-module--basicDarkHero__title--animate--wzvov";
export var basicDarkHero__titleAnimateInView = "BasicDarkHero-module--basicDarkHero__title--animate--inView--ySxPY";
export var basicDarkHero__titleHasImage = "BasicDarkHero-module--basicDarkHero__title--hasImage--EojjX";
export var basicDarkHero__titleNews = "BasicDarkHero-module--basicDarkHero__title--news--XhWz+";
export var basicDarkHero__titleNoImage = "BasicDarkHero-module--basicDarkHero__title--noImage--coXcg";
export var basicDarkHero__titleNoMargin = "BasicDarkHero-module--basicDarkHero__title--noMargin--L8gH1";
export var basicDarkHero__titlePlan = "BasicDarkHero-module--basicDarkHero__title--plan--44rlu";
export var fadeInAnimation = "BasicDarkHero-module--fadeInAnimation--HAQHI";
export var fadeOutAnimation = "BasicDarkHero-module--fadeOutAnimation--ign6z";
export var slideIn = "BasicDarkHero-module--slideIn--WdZmA";
export var slideInMobileNav = "BasicDarkHero-module--slideInMobileNav--99Fu1";
export var slideInNav = "BasicDarkHero-module--slideInNav--RjR+k";
export var slideInTop = "BasicDarkHero-module--slideInTop--kxBrn";
export var slideOut = "BasicDarkHero-module--slideOut--hJHhB";
export var slideOutTop = "BasicDarkHero-module--slideOutTop--2SqiS";