import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import AnimateHeight from 'react-animate-height';
import { useSourcedLink } from '@hooks';
import {
  DISMISS_ALERT_BAR, LC_WHITE, SMALL, BLANK,
} from '@constants';
import { getKeyValue } from '@utils';

import Close from '../../../assets/close.inline.svg';
import * as styles from './AlertBar.module.scss';
import AlertRichText from './AlertBarContent';

const AlertBar = (props) => {
  const [closed, setClosed] = useState(false);
  const [closing, setClosing] = useState(false);
  const {
    alertBarContent, alertBarLink: cta,
  } = props;

  const { externalLink: ctaexternalLink } = cta || {};
  const { path: ctapath } = getKeyValue(cta, 'internalPageLink') || {};
  const { url: ctaurl } = getKeyValue(cta, 'internalFileLink.file') || {};

  const [makeSourcedLink] = useSourcedLink();

  const ctalinkProps = {
    href: makeSourcedLink(ctapath || ctaurl || ctaexternalLink),
  };

  if (ctaexternalLink) {
    ctalinkProps.target = BLANK;
  }

  const CloseBar = () => {
    if (!closed) {
      setClosing(true);
      setTimeout(() => {
        setClosed(true);
        sessionStorage.setItem(DISMISS_ALERT_BAR, 'true');
      }, 500);
      setTimeout(() => {
        setClosing(false);
      }, 550);
    }
  };

  useEffect(() => {
    setClosed(!!sessionStorage.getItem(DISMISS_ALERT_BAR));
  }, []);

  return (
    <React.Fragment>
      {!closed && ctalinkProps.href ? (

        <AnimateHeight
          duration={500}
          height={(closing || closed) ? 0 : 'auto'}
          style={{ width: '100%' }}
        >
          <div
            className={cx(styles.alertbar, {
              [styles.alertbarClosing]: closing,
            })}
            role="alert"
          >
            <div
              className={cx(styles.alertbar__wrapper, {
                [styles.alertbar__wrapperOpening]: !closing,
              })}
            >
              {alertBarContent && (
              <div className={styles.alertbar__content}>
                <AlertRichText
                  ctalink={ctalinkProps}
                  content={alertBarContent}
                  color={LC_WHITE}
                  size={SMALL}
                />
              </div>
              )}
            </div>
            <button className={styles.alertbar__closebutton} type="button" onClick={CloseBar}>
              <Close />
            </button>
          </div>
        </AnimateHeight>
      ) : null}
    </React.Fragment>
  );
};

export default AlertBar;
