import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getKeyValue } from '@utils';
import {
  TRANSPARENT, LARGE, SINGLE_COL, LC_BLACK,
} from '@constants';
import {
  BasicRichText, BasicDarkHero, ContentLayout, Layout,
} from '@molecules';

const NotFound = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        ...SiteMetadata
      }
      page: contentfulPage(path: { eq: "/404" }) {
        path
        disableDonationBar
        metaTitle
        metaImage {
          file {
            url
          }
        }
        pageContent {
          ... on ContentfulPageContentContentDetail {
            title
            id
            introContent {
              raw
              references {
                ... on ContentfulPage {
                  contentful_id
                  path
                }
              }
            }
          }
        }
      }
      settings: contentfulGlobalSiteSettings(language: { eq: "en-US" }) {
        ...GlobalSiteSettings
      }
    }
  `);

  const {
    site, page, settings, languageLabels,
  } = data || {};

  const locale = 'en-US';
  const { siteTitle } = settings || {};
  const { social } = getKeyValue(site, 'siteMetadata') || {};
  const {
    metaTitle, description, metaImage, path, pageContent, disableDonationBar,
  } = page || {};
  const {
    title: pageTitle, attribution, releaseDate, heroImage, introContent,
  } = pageContent || {};
  const { url: metaImageUrl } = getKeyValue(metaImage, 'file') || {};
  const noImage = !heroImage || heroImage.fluid;

  return (
    <Layout
      metaDescription={description}
      metaUrl={`https://${process.env.GATSBY_HOST_DOMAIN}${path}`}
      metaTitle={`${metaTitle} | ${siteTitle}`}
      metaImageUrl={metaImageUrl}
      fbAppID={social.fbAppID}
      twitter={social.twitter}
      locale={locale}
      navTheme={TRANSPARENT}
      settings={settings}
      navAnimate
      languageLabels={languageLabels}
      disableDonationBar={disableDonationBar}
    >
      <BasicDarkHero
        title={pageTitle}
        byline={attribution}
        releaseDate={releaseDate}
        heroImage={heroImage}
      />
      <ContentLayout layout={`${SINGLE_COL}${noImage ? 'noimage' : ''}`}>
        {introContent && (
          <div style={{ minHeight: 'calc(100vh - 800px)' }}>
            <BasicRichText
              content={introContent}
              size={LARGE}
              textWidth={SINGLE_COL}
              color={LC_BLACK}
            />
          </div>
        )}
      </ContentLayout>
    </Layout>
  );
};

export default NotFound;
