import React, { useState, useEffect, useContext } from 'react';
import FocusTrap from 'focus-trap-react';
import cx from 'classnames';

import ModalContext from '../../../context/ModalContext';
import Close from '../../../assets/close.inline.svg';
import * as styles from './ModalVideo.module.scss';

function ModalVideo(props) {
  const [closing, setClosing] = useState(false);
  const {
    modalRef, videoTitle, videoYoutubeId,
  } = props;

  const { active, toggleModal } = useContext(ModalContext);

  const CloseBar = () => {
    if (active) {
      setClosing(true);
      setTimeout(() => {
        toggleModal();
      }, 400);
      setTimeout(() => {
        setClosing(false);
      }, 500);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      const page = document.getElementsByTagName('html');
      if (page && page[0]) {
        if (!active) {
          page[0].classList.remove('overlay-active');
        } else {
          page[0].classList.add('overlay-active');
        }
      }
    }, 500);
  }, [active]);

  return (
    <React.Fragment>
      {' '}
      {active && (
      <div
        ref={modalRef}
        aria-labelledby={videoYoutubeId}
        role="dialog"
        aria-modal="true"
        className={cx(styles.modalvideo__container, {
          [styles.modalvideo__containerClosing]: closing,
        })}
      >
        <FocusTrap focusTrapOptions={{ clickOutsideDeactivates: true }}>
          <div className={styles.modalvideo__playerwrap}>
            <div className={styles.modalvideo__player}>
              <iframe
                className="youtube-player"
                src={`https://www.youtube.com/embed/${videoYoutubeId}?autoplay=1&amp;rel=0`}
                frameBorder="0"
                allow="fullscreen; autoplay; encrypted-media"
                allowFullScreen=""
                title={videoTitle}
                id={videoYoutubeId}
              />
            </div>
            <button
              className={styles.modalvideo__close}
              onClick={CloseBar}
              type="button"
            >
              <Close />
            </button>
          </div>
        </FocusTrap>
      </div>
      )}
    </React.Fragment>
  );
}

export default ModalVideo;
