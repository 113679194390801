// extracted by mini-css-extract-plugin
export var fadeInAnimation = "Video-module--fadeInAnimation---ewf3";
export var fadeOutAnimation = "Video-module--fadeOutAnimation--akXTx";
export var slideIn = "Video-module--slideIn--BckLx";
export var slideInMobileNav = "Video-module--slideInMobileNav--4XUmP";
export var slideInNav = "Video-module--slideInNav--zODoS";
export var slideInTop = "Video-module--slideInTop--SRwOT";
export var slideOut = "Video-module--slideOut--1kap-";
export var slideOutTop = "Video-module--slideOutTop--kbikl";
export var video__caption = "Video-module--video__caption--zHkZi";
export var video__container = "Video-module--video__container--SPTT9";
export var video__containerinline = "Video-module--video__containerinline---V1Vb";
export var video__image = "Video-module--video__image--veZk4";
export var video__imagewrapper = "Video-module--video__imagewrapper--Q+rl2";
export var video__playbutton = "Video-module--video__playbutton--D7Y+y";
export var video__title = "Video-module--video__title--rWd31";