import React from 'react';
import cx from 'classnames';
import {
  MEDIUM, SINGLE_COL, LC_BLACK,
} from '@constants';
import { BasicRichText } from '@molecules';

import * as styles from './IssueIntroduction.module.scss';

const IssueIntroduction = ({ introduction }) => (
  <section className={cx(styles.issueIntroduction)} key="intro" id="intro">
    {introduction && (
      <BasicRichText
        content={introduction}
        size={MEDIUM}
        textWidth={SINGLE_COL}
        color={LC_BLACK}
      />
    )}
  </section>
);

export default IssueIntroduction;
