import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import * as styles from './EventListing.module.scss';

const EventList = ({
  formId,
  formTheme = 'Light',
  databag = 'nobody',
  addClass = '',
  size = 'Large'
}) => {

  const [render, setRender] = useState(false);

  if (!formId) {
    return <React.Fragment />;
  }

  useEffect(() => {
    if (!window.nvtag) {
      setRender(true);
    } else {
      // window.location.reload();
    }
  }, []);

  return (
    <div className={styles.eventListing}>
      {render && (
        <Helmet>
          <script type="text/javascript" src="https://assets.targetedaction.net/embed.js" />
          <script type="text/javascript" src="https://assets.targetedaction.net/embed.js" crossOrigin="anonymous" />
        </Helmet>
      )}
      <div
        key={formId}
        className={`oa-page-embed oa-page-embed--${formTheme} oa-page-embed--${size} ${addClass}`}
        data-formdef-endpoint="//formdefs.s3.amazonaws.com/api.myngp.com"
        data-databag={databag}
        data-inline-errors
        data-labels="above"
        data-mobile-autofocus={false}
        data-page-url={`https://secure.ngpvan.com/p/${formId}`}
      />
    </div>
  );
};

export default EventList;
