import React from 'react';
import cx from 'classnames';
import { capitalizeFirstLetter } from '@utils';

import * as styles from './ContentLayout.module.scss';

const Cta = ({
  children, layout, hasBottomSection,
}) => (
  <section
    className={cx(styles.contentlayout, styles.contentlayoutSinglecol, {
      [styles[`contentlayout${capitalizeFirstLetter(layout)}`]]: layout,
      [styles.contentlayoutHasBottomSection]: hasBottomSection,
    })}
  >
    {children}
  </section>
);

export default Cta;
