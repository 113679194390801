import React, { useContext } from 'react';
import cx from 'classnames';
import {
  PLAN, LC_BASE, LC_WHITE, LIGHT, DARK,
} from '@constants';
import { BasicRichText } from '@molecules';
import { FormWrapper } from '@organisms';
import { capitalizeFirstLetter } from '@utils';

import FormContext from '../../../context/FormContext';
import * as styles from './InlineSignUpAsk.module.scss';

const InlineSignUpAsk = (props) => {
  const {
    header, intro, donationDisclaimer, pageType, mobileDisclaimer, id, postSubmitDonationAsk,
  } = props;
  const { submitted } = useContext(FormContext);
  const formSubmitted = submitted && submitted[id]
    && postSubmitDonationAsk && postSubmitDonationAsk.id;

  return (
    <div
      className={cx(styles.inlinesignupask, {
        [styles[`inlinesignupask${capitalizeFirstLetter(pageType)}`]]: pageType,
      })}
    >
      <div
        className={cx(styles.inlinesignupask__wrapper, {
          [styles[`inlinesignupask__wrapper${capitalizeFirstLetter(pageType)}`]]: pageType,
          [styles.inlinesignupask__wrapperFormSubmitted]: formSubmitted,
        })}
      >
        <div
          className={cx(styles.inlinesignupask__content, {
            [styles.inlinesignupask__contentFormSubmitted]: formSubmitted,
          })}
        >
          {header && (
            <h2
              className={cx(styles.inlinesignupask__heading, {
                [styles.inlinesignupask__headingNoIntro]: !intro,
                [styles[`inlinesignupask__heading${capitalizeFirstLetter(pageType)}`]]: pageType,
              })}
            >
              {header}
            </h2>
          )}
          {intro && (
            <BasicRichText content={intro} color={pageType === PLAN ? LC_BASE : LC_WHITE} />
          )}
        </div>
        <div className={cx(styles.inlinesignupask__ask, {
          [styles.inlinesignupask__askFormSubmitted]: formSubmitted,
        })}
        >
          <FormWrapper
            {...props}
            formTheme={(pageType === PLAN) ? LIGHT : DARK}
            donationDisclaimer={donationDisclaimer}
            mobileDisclaimer={mobileDisclaimer}
          />
        </div>
      </div>
    </div>
  );
};

export default InlineSignUpAsk;
