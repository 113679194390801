// extracted by mini-css-extract-plugin
export var fadeInAnimation = "SignUpSection-module--fadeInAnimation--uDiZd";
export var fadeOutAnimation = "SignUpSection-module--fadeOutAnimation--8jOIs";
export var signupsection = "SignUpSection-module--signupsection--qa1yc";
export var signupsectionDarkBlue = "SignUpSection-module--signupsection--darkBlue--BmM9r";
export var signupsectionFirstSection = "SignUpSection-module--signupsection--firstSection--MIddd";
export var signupsectionLightBlue = "SignUpSection-module--signupsection--lightBlue--qzCNs";
export var signupsectionPreviousDonation = "SignUpSection-module--signupsection--previousDonation--t0zD4";
export var signupsectionWhite = "SignUpSection-module--signupsection--white--YnRa+";
export var signupsection__ask = "SignUpSection-module--signupsection__ask--cTBpX";
export var signupsection__content = "SignUpSection-module--signupsection__content--cutGU";
export var signupsection__contentFirstSection = "SignUpSection-module--signupsection__content--firstSection--P+68F";
export var signupsection__heading = "SignUpSection-module--signupsection__heading--vAvrD";
export var signupsection__wrapper = "SignUpSection-module--signupsection__wrapper--kwpam";
export var signupsection__wrapperDarkBlue = "SignUpSection-module--signupsection__wrapper--darkBlue--KnkIn";
export var signupsection__wrapperLightBlue = "SignUpSection-module--signupsection__wrapper--lightBlue--pNNYs";
export var signupsection__wrapperWhite = "SignUpSection-module--signupsection__wrapper--white--KKSUW";
export var slideIn = "SignUpSection-module--slideIn--PuiC0";
export var slideInMobileNav = "SignUpSection-module--slideInMobileNav--Cqu5D";
export var slideInNav = "SignUpSection-module--slideInNav--ZpAVF";
export var slideInTop = "SignUpSection-module--slideInTop--vpVru";
export var slideOut = "SignUpSection-module--slideOut--GntqX";
export var slideOutTop = "SignUpSection-module--slideOutTop--yAH+h";