// extracted by mini-css-extract-plugin
export var biosection = "BioSection-module--biosection--ZI-N9";
export var biosectionDarkBlue = "BioSection-module--biosection--darkBlue--dlbeH";
export var biosectionFirstSection = "BioSection-module--biosection--firstSection--PvKzX";
export var biosectionLightBlue = "BioSection-module--biosection--lightBlue--H9HMX";
export var biosectionPreviousDonation = "BioSection-module--biosection--previousDonation--AcyeX";
export var biosectionWhite = "BioSection-module--biosection--white--MfXG4";
export var biosection__content = "BioSection-module--biosection__content--Yp4Rn";
export var biosection__contentFirstSection = "BioSection-module--biosection__content--firstSection--6mRTm";
export var biosection__ctawrapper = "BioSection-module--biosection__ctawrapper--3gjvt";
export var biosection__heading = "BioSection-module--biosection__heading--qY-bN";
export var biosection__headingDesktop = "BioSection-module--biosection__heading--desktop--bT2l5";
export var biosection__headingMobile = "BioSection-module--biosection__heading--mobile---cD4I";
export var biosection__image = "BioSection-module--biosection__image--9iEz+";
export var biosection__imageFirstSection = "BioSection-module--biosection__image--firstSection--u0vHs";
export var biosection__imagewrapper = "BioSection-module--biosection__imagewrapper--nEVhF";
export var biosection__wrapper = "BioSection-module--biosection__wrapper--efVr6";
export var fadeInAnimation = "BioSection-module--fadeInAnimation--F1XIh";
export var fadeOutAnimation = "BioSection-module--fadeOutAnimation--azSFY";
export var slideIn = "BioSection-module--slideIn--VdyAC";
export var slideInMobileNav = "BioSection-module--slideInMobileNav--X+wp5";
export var slideInNav = "BioSection-module--slideInNav--8xUVY";
export var slideInTop = "BioSection-module--slideInTop--VMfoQ";
export var slideOut = "BioSection-module--slideOut--85Dl2";
export var slideOutTop = "BioSection-module--slideOutTop--fpHL4";