import React from 'react';
import cx from 'classnames';
import {
  MEDIUM, SINGLE_COL, LC_BLACK,
} from '@constants';
import { BasicRichText } from '@molecules';

import * as styles from './IssueConclusion.module.scss';

const IssueConclusion = ({ conclusion }) => (
  <section className={cx(styles.issueConclusion)} key="conclusion" id="conclusion">
    {conclusion && (
      <BasicRichText
        content={conclusion}
        size={MEDIUM}
        textWidth={SINGLE_COL}
        color={LC_BLACK}
      />
    )}
  </section>
);

export default IssueConclusion;
