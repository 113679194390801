import React from 'react';
import cx from 'classnames';
import {
  DARK_BLUE, DARK, LIGHT, LC_WHITE, LC_BASE,
} from '@constants';
import { BasicRichText } from '@molecules';
import { FormWrapper } from '@organisms';
import { capitalizeFirstLetter } from '@utils';

import * as styles from './SignUpSection.module.scss';

const SignUpSection = (props) => {
  const {
    sectionTheme = 'dark', sectionTitle, summaryContent, firstSection, contentReferences,
    donationDisclaimer, previousSection, previousSectionDonation, previousSectionSignup,
    mobileDisclaimer, googleMacro
  } = props;
  const themeAppend = capitalizeFirstLetter(sectionTheme.replace(/\s/g, ''));
  const formContent = contentReferences && contentReferences[0];
  const { theme: prevSectionTheme } = previousSection || {};
  const prevThemeAppend = prevSectionTheme ? capitalizeFirstLetter(prevSectionTheme.replace(/\s/g, '')) : themeAppend;
  const formTheme = sectionTheme === DARK_BLUE ? DARK : LIGHT;
  const richTextTheme = sectionTheme === DARK_BLUE ? LC_WHITE : LC_BASE;

  return (
    <section
      className={cx(styles.signupsection,
        styles[`signupsection${prevThemeAppend}`], {
          [styles.signupsectionFirstSection]: firstSection,
          [styles.signupsectionPreviousDonation]: (
            previousSectionDonation || previousSectionSignup),
        })}
    >
      <div
        className={cx(styles.signupsection__wrapper,
          styles[`signupsection__wrapper${themeAppend}`])}
      >
        <div
          className={cx(styles.signupsection__content, {
            [styles.signupsection__contentFirstSection]: firstSection,
          })}
        >
          {sectionTitle && (
            <h2 className={cx(styles.signupsection__heading)}>
              {sectionTitle}
            </h2>
          )}
          {summaryContent && (
            <BasicRichText content={summaryContent} color={richTextTheme} />
          )}
        </div>
        <div className={styles.signupsection__ask}>
          <FormWrapper
            {...formContent}
            formTheme={formTheme}
            donationDisclaimer={donationDisclaimer}
            mobileDisclaimer={mobileDisclaimer}
            googleMacro={googleMacro}
          />
        </div>
      </div>
    </section>
  );
};

export default SignUpSection;
