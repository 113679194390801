import React, {
  useState, useRef, useEffect, useContext,
} from 'react';
import {
  Formik, Field, Form, ErrorMessage,
} from 'formik';
import cx from 'classnames';
import AnimateHeight from 'react-animate-height';
import {
  PLAN, NEWS, LC_BASE, XSMALL, LC_WHITE, DARK,
} from '@constants';
import { BasicRichText, HeroDonationAsk } from '@molecules';
import { DonationSection, InlineDonationAsk } from '@organisms';
import { useFade } from '@hooks';
import { encode, capitalizeFirstLetter, gtmEvent } from '@utils';

import FormContext from '../../../context/FormContext';
import { formValidators } from './validation';
import * as styles from './Form.module.scss';

const getAsField = (fieldType) => {
  let asField;
  switch (fieldType) {
    case 'select':
      asField = 'select';
      break;
    case 'textarea':
      asField = 'textarea';
      break;
    default:
      asField = 'input';
  }
  return asField;
};

const FormWrapper = ({
  contentfulfields, formId, submitButtonLabel, id, postSubmitMessage,
  formTheme = 'dark', postSubmitDonationAsk, formType, mobileDisclaimer,
  donationDisclaimer, submitButtonDesktopWidth, pageType, googleMacro,
  submitButtonMobileWidth, formComponentType, defaultFormSourcing,
}) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const formRef = useRef();
  const [isFading, hasFaded] = useFade(formSubmitted, 500);
  const { submitted, toggleSubmitted } = useContext(FormContext);

  useEffect(() => {
    if (hasFaded) {
      const timer = setTimeout(() => {
        if (formRef.current) {
          const { offsetTop, offsetHeight } = formRef.current;
          window.scrollBy({
            top: -(offsetTop + offsetHeight),
            left: 0,
            behavior: 'smooth',
          });
        }
      }, 550);
      return () => clearTimeout(timer);
    }
    return () => {};
  }, [hasFaded]);

  if (!contentfulfields || !formId) {
    return <React.Fragment />;
  }

  const initialValues = {};
  let emailFieldName = '';
  let mobileFieldName = '';
  contentfulfields.forEach((field) => {
    initialValues[field.name] = '';
    if (field.type === 'email') {
      emailFieldName = field.name;
    }
    if (field.type === 'tel') {
      mobileFieldName = field.name;
    }
  });

  return (
    <div
      ref={formRef}
      className={cx({
        [styles[`form${capitalizeFirstLetter(formTheme)}`]]: formTheme,
      })}
    >
      <AnimateHeight
        duration={500}
        height={(hasFaded || isFading) ? 0 : 'auto'}
        aria-hidden={hasFaded}
      >
        <div
          id={id}
          className={cx(styles.form__outerWrapper, {
            [styles[`form__outerWrapper${capitalizeFirstLetter(pageType)}`]]: pageType,
          })}
        >
          <Formik
            initialValues={initialValues}
            onSubmit={async (values, actions) => {
              const formValues = { ...values };
              const currentDate = (new Date(Date.now())).toISOString();
              formValues.type = formType || 'SignupForm';

              if (window) {
                const urlSearchParams = new URLSearchParams(window.location.search);
                const params = Object.fromEntries(urlSearchParams.entries());
                if (params.ms) {
                  formValues.MarketSource = params.ms;
                } else if (params.refcode) {
                  formValues.MarketSource = params.refcode;
                } else if (defaultFormSourcing) {
                  formValues.MarketSource = defaultFormSourcing;
                }
              }

              if (values[emailFieldName]) {
                formValues.YesSignMeUpForUpdatesForBinder = true;
              }

              if (values[mobileFieldName]) {
                formValues.SmsSubscribeMobilePhone = true;
              }

              if (formValues) {
                Object.keys(formValues).forEach((key) => {
                  if (Array.isArray(formValues[key])) {
                    formValues[key].forEach((selectedOption) => {
                      formValues[selectedOption] = true;
                    });
                    delete formValues[key];
                  }
                });
              }

              fetch('/', {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: encode({ 'form-name': formId, ...formValues }),
              })
              .then((resp) => {
                if (!resp.ok) {
                  console.error(resp);
                }
              })
              .catch((error) => {
                console.error(error);
              })
              .finally(() => {
                console.log('post 1')
              });

              fetch((googleMacro || 'https://script.google.com/macros/s/AKfycbycWHFhbv1NiaojW3uAdSkqNpTUzmVF4ptg2SDJ571Xo7cSvCuzEBHy8jcT_tzMKG8UXQ/exec'), {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: encode({ DateTimestampSubmitted: currentDate, ...formValues }),
              })
                .then((resp) => {
                  if (!resp.ok) {
                    console.error(resp);
                  } else {
                    actions.resetForm();
                  }
                })
                .catch((error) => {
                  console.error(error);
                })
                .finally(() => {
                  gtmEvent('ngp-form-submission,', { formId });
                  setFormSubmitted(true);
                  actions.setSubmitting(false);
                  toggleSubmitted({
                    ...submitted,
                    [id]: true,
                  });
                });
            }}
            validate={() => ({})}
          >
            {({ errors, touched }) => (
              <Form
                className={cx(styles.form, {
                  [styles.formIsFading]: isFading,
                })}
                action={formId}
                data-netlify
              >
                {contentfulfields.map((field) => {
                  const {
                    type, name, desktopWidth, mobileWidth, required, id: fieldId,
                    label, errorMessage, placeholder, selectradiocheckboxOptions,
                  } = field;
                  const asField = getAsField(type);
                  const validationType = required ? 'required' : 'optional';
                  const validationFunction = formValidators[type]
                  && formValidators[type][validationType];
                  const hasError = errors[name] && touched[name];
                  if (type === 'email' || type === 'text' || type === 'tel' || type === 'textarea' || type === 'postalcode') {
                    return (
                      <React.Fragment>
                        <div
                          key={fieldId}
                          className={cx(styles.form__fieldWrapper, {
                            [styles[`form__fieldWrapperDesktop${desktopWidth}`]]: desktopWidth,
                            [styles[`form__fieldWrapperMobile${mobileWidth}`]]: mobileWidth,
                          })}
                        >
                          <label
                            htmlFor={fieldId}
                            className={cx(styles.form__label, {
                              [styles[`form__label${capitalizeFirstLetter(formTheme)}`]]: formTheme,
                            })}
                          >
                            {label}
                            {required && (
                            <span className={styles.form__required}> *</span>
                            )}
                          </label>
                          <Field
                            id={fieldId}
                            name={name}
                            type={type}
                            as={asField}
                            validate={(value) => {
                              if (typeof validationFunction === 'function') {
                                return validationFunction(value, label, errorMessage);
                              }
                              return '';
                            }}
                            aria-describedby={`${fieldId}-error`}
                            className={cx(styles[`form__field${capitalizeFirstLetter(asField)}`], {
                              [styles[`form__field${capitalizeFirstLetter(asField)}HasError`]]: hasError,
                            })}
                          />
                          <span
                            className={styles.form__errorMessage}
                            aria-live="assertive"
                            id={`${fieldId}-error`}
                          >
                            <ErrorMessage name={name} />
                          </span>
                        </div>
                        {name === mobileFieldName && mobileDisclaimer && (formTheme === 'light' && pageType !== PLAN && pageType !== NEWS) && (
                          <div style={{ marginBottom: '20px' }}>
                            <BasicRichText
                              content={mobileDisclaimer}
                              size={XSMALL}
                              color={LC_BASE}
                            />
                          </div>
                        )}
                      </React.Fragment>
                    );
                  }
                  if (type === 'select') {
                    return (
                      <div
                        className={cx(styles.form__fieldWrapper, styles[`form__fieldWrapper${capitalizeFirstLetter(asField)}`], {
                          [styles.form__fieldWrapperHasError]: hasError,
                          [styles[`form__fieldWrapperDesktop${desktopWidth}`]]: desktopWidth,
                          [styles[`form__fieldWrapperMobile${mobileWidth}`]]: mobileWidth,
                        })}
                        key={fieldId}
                      >
                        <label
                          className={cx(styles.form__label, {
                            [styles[`form__label${capitalizeFirstLetter(formTheme)}`]]: formTheme,
                          })}
                          htmlFor={fieldId}
                        >
                          {label}
                          {required && (
                            <span className={styles.form__required}> *</span>
                          )}
                        </label>
                        <Field
                          id={fieldId}
                          name={name}
                          type={type}
                          as={asField}
                          aria-describedby={`${fieldId}-error`}
                          className={cx(styles[`form__field${capitalizeFirstLetter(asField)}`], {
                            [styles[`form__field${capitalizeFirstLetter(asField)}HasError`]]: hasError,
                          })}
                          validate={(value) => {
                            if (typeof validationFunction === 'function') {
                              return validationFunction(value, label, errorMessage);
                            }
                            return '';
                          }}
                        >
                          <option value="none">{placeholder || 'Select an option'}</option>
                          {selectradiocheckboxOptions
                          && selectradiocheckboxOptions.map((option) => (
                            <option value={option.value}>{option.key}</option>
                          ))}
                        </Field>
                        <span
                          className={styles.form__errorMessage}
                          aria-live="assertive"
                          id={`${fieldId}-error`}
                        >
                          <ErrorMessage name={name} />
                        </span>
                      </div>
                    );
                  }

                  if (type === 'checkbox') {
                    return (
                      <div
                        key={fieldId}
                        role="group"
                        className={cx(styles.form__fieldWrapper, {
                          [styles[`form__fieldWrapperDesktop${desktopWidth}`]]: desktopWidth,
                          [styles[`form__fieldWrapperMobile${mobileWidth}`]]: mobileWidth,
                        })}
                      >
                        <label
                          className={cx(styles.form__label,
                            `field-group-label-${type}`, {
                              [styles[`form__label${capitalizeFirstLetter(formTheme)}`]]: formTheme,
                              [styles.form__labelHasError]: hasError,
                            })}
                          htmlFor={fieldId}
                        >
                          {label}
                          {required && (
                            <span className={styles.form__required}> *</span>
                          )}
                        </label>
                        {selectradiocheckboxOptions
                        && selectradiocheckboxOptions.map((option) => (
                          <label
                            htmlFor={`${fieldId}-${option.value}`}
                            className={cx(styles.form__checkboxLabel,
                              `${styles.form__label}-${field.type}`, {
                                [`${styles.form__label}-${field.type}HasError`]: hasError,
                                [styles[`form__label${capitalizeFirstLetter(formTheme)}`]]: formTheme,
                              })}
                          >
                            {option.key}
                            <Field
                              type={type}
                              name={name}
                              value={option.value}
                              className={cx(styles[`form__field${capitalizeFirstLetter(type)}`], {
                                [styles[`form__field${capitalizeFirstLetter(type)}HasError`]]: hasError,
                              })}
                              as={asField}
                              validate={(value) => {
                                if (typeof validationFunction === 'function') {
                                  return validationFunction(value, label, errorMessage);
                                }
                                return '';
                              }}
                              aria-describedby={`${fieldId}-error`}
                            />
                            <span className={styles.form__checkmark} />
                          </label>
                        ))}
                        <span
                          className={styles.form__errorMessage}
                          aria-live="assertive"
                          id={`${fieldId}-error`}
                        >
                          <ErrorMessage name={name} />
                        </span>
                      </div>
                    );
                  }

                  return (
                    <div
                      key={fieldId}
                      className={cx(styles.form__fieldWrapper, {
                        [styles[`form__fieldWrapperDesktop${desktopWidth}`]]: desktopWidth,
                        [styles[`form__fieldWrapperMobile${mobileWidth}`]]: mobileWidth,
                      })}
                    >
                      <label
                        htmlFor={fieldId}
                        className={cx(styles.form__label, {
                          [styles[`form__label${capitalizeFirstLetter(formTheme)}`]]: formTheme,
                        })}
                      >
                        {label}
                      </label>
                      <Field
                        id={fieldId}
                        className={cx(styles[`form__field${capitalizeFirstLetter(asField)}`], {
                          [styles[`form__field${capitalizeFirstLetter(asField)}HasError`]]: hasError,
                        })}
                        name={name}
                        type={type}
                        as={asField}
                      />
                    </div>
                  );
                })}
                <div
                  className={cx(styles.form__fieldWrapper,
                    styles.form__fieldWrapperSubmit,
                    styles.form__fieldWrapperMobile100,
                    {
                      [styles[`form__fieldWrapperDesktop${submitButtonDesktopWidth}`]]: submitButtonDesktopWidth,
                      [styles[`form__fieldWrapperMobile${submitButtonMobileWidth}`]]: submitButtonMobileWidth,
                    })}
                >
                  <button
                    className={cx(styles.form__submit, {
                      [styles[`form__submit${capitalizeFirstLetter(pageType)}`]]: pageType,
                    })}
                    type="submit"
                  >
                    {submitButtonLabel || 'Submit'}
                  </button>
                </div>
                {mobileFieldName && mobileDisclaimer && (
                  formTheme === DARK || pageType === PLAN || pageType === NEWS
                ) && (
                  <BasicRichText
                    content={mobileDisclaimer}
                    size={XSMALL}
                    color={formTheme === DARK ? LC_WHITE : LC_BASE}
                  />
                )}
              </Form>
            )}
          </Formik>
        </div>
      </AnimateHeight>
      {hasFaded && (
        <React.Fragment>
          {postSubmitDonationAsk && formComponentType === 'heroAction' && (
            <HeroDonationAsk
              {...postSubmitDonationAsk}
              donationDisclaimer={donationDisclaimer}
            />
          )}
          {postSubmitDonationAsk && formComponentType !== 'heroAction' && pageType !== PLAN && pageType !== NEWS && (
            <DonationSection
              contentReferences={[postSubmitDonationAsk]}
              donationDisclaimer={donationDisclaimer}
              pageType={pageType}
            />
          )}
          {postSubmitDonationAsk && formComponentType !== 'heroAction' && (pageType !== PLAN || pageType !== NEWS) && (
            <InlineDonationAsk
              {...postSubmitDonationAsk}
              donationDisclaimer={donationDisclaimer}
              pageType={pageType}
            />
          )}
          {postSubmitMessage && formComponentType !== 'heroAction' && !postSubmitDonationAsk && (
            <div className={styles.form__thankyou}>
              <BasicRichText content={postSubmitMessage} />
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default FormWrapper;
