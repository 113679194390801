import React from 'react';
import cx from 'classnames';
import {
  PLAN, LC_WHITE, LC_BASE, XSMALL, LEFT, BLANK,
  NO_OPENER,
} from '@constants';
import { BasicRichText } from '@molecules';
import { useSourcedLink } from '@hooks';
import { appendQueryString, getExpressLink, capitalizeFirstLetter } from '@utils';

import * as styles from './InlineDonationAsk.module.scss';

const InlineDonationAsk = (props) => {
  const {
    header, intro, donationDisclaimer, donationLink, pageType,
    desktopDonationButtons, mobileDonationButtons,
  } = props;
  const buttonCount = desktopDonationButtons ? desktopDonationButtons.length : 0;
  const mobButtonCount = mobileDonationButtons ? mobileDonationButtons.length : 0;
  const [makeSourcedLink] = useSourcedLink();

  return (
    <div
      className={cx(styles.inlinedonationask, {
        [styles[`inlinedonationask${capitalizeFirstLetter(pageType)}`]]: pageType,
      })}
    >
      <div
        className={cx(styles.inlinedonationask__wrapper, {
          [styles[`inlinedonationask__wrapper${capitalizeFirstLetter(pageType)}`]]: pageType,
        })}
      >
        <div
          className={cx(styles.inlinedonationask__content)}
        >
          {header && (
            <h2
              className={cx(styles.inlinedonationask__heading, {
                [styles.inlinedonationask__headingNoIntro]: !intro,
                [styles[`inlinedonationask__heading${capitalizeFirstLetter(pageType)}`]]: pageType,
              })}
            >
              {header}
            </h2>
          )}
          {intro && (
            <BasicRichText content={intro} color={pageType === PLAN ? LC_BASE : LC_WHITE} />
          )}
        </div>
        <div className={styles.inlinedonationask__ask}>
          {desktopDonationButtons && (
            <ul className={styles.inlinedonationask__buttons}>
              {desktopDonationButtons.map((amount) => {
                const expLink = getExpressLink({ donationLink, amount });
                return (
                  <li
                    key={amount.key}
                    className={cx(styles.inlinedonationask__button, styles[`inlinedonationask__button${buttonCount}`], {
                      [styles[`inlinedonationask__button${capitalizeFirstLetter(pageType)}`]]: pageType,
                    })}
                  >
                    <a
                      href={makeSourcedLink(appendQueryString(expLink, `amount=${amount.value}`))}
                      target={BLANK}
                      rel={NO_OPENER}
                    >
                      {amount.key}
                    </a>
                  </li>
                );
              })}
            </ul>
          )}
          {mobileDonationButtons && donationLink && (
            <ul className={cx(styles.inlinedonationask__buttons,
              styles.inlinedonationask__buttonsMobile)}
            >
              {mobileDonationButtons.map((amount) => {
                const expLink = getExpressLink({ donationLink, amount });
                return (
                  <li
                    className={cx(styles.inlinedonationask__button, styles[`inlinedonationask__button${mobButtonCount}`], {
                      [styles[`inlinedonationask__button${capitalizeFirstLetter(pageType)}`]]: pageType,
                    })}
                    key={`${amount.key}-mobile`}
                  >
                    <a
                      href={makeSourcedLink(appendQueryString(expLink, `amount=${amount.value}`))}
                      target={BLANK}
                      rel={NO_OPENER}
                    >
                      {amount.key}
                    </a>
                  </li>
                );
              })}
            </ul>
          )}
          {(donationDisclaimer) && (
            <BasicRichText
              content={donationDisclaimer}
              size={XSMALL}
              color={pageType === PLAN ? LC_BASE : LC_WHITE}
              align={LEFT}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default InlineDonationAsk;
