// extracted by mini-css-extract-plugin
export var fadeInAnimation = "Footer-module--fadeInAnimation--zNO0X";
export var fadeOutAnimation = "Footer-module--fadeOutAnimation--rP5jq";
export var footer = "Footer-module--footer--zaR3N";
export var footer__content = "Footer-module--footer__content--xB6lp";
export var footer__contentrow = "Footer-module--footer__contentrow--vIpYK";
export var footer__copyright = "Footer-module--footer__copyright--iIhwi";
export var footer__cta = "Footer-module--footer__cta--mVsQZ";
export var footer__disclaimer = "Footer-module--footer__disclaimer--67m9b";
export var footer__footerlinks = "Footer-module--footer__footerlinks--HO-ZX";
export var footer__lastcol = "Footer-module--footer__lastcol--yc7Cz";
export var footer__logo = "Footer-module--footer__logo--MZ4O1";
export var footer__primarylink = "Footer-module--footer__primarylink--GPklT";
export var footer__primarylinks = "Footer-module--footer__primarylinks--tQWVG";
export var footer__secondarylink = "Footer-module--footer__secondarylink--tSyGa";
export var footer__secondarylinks = "Footer-module--footer__secondarylinks--lpAxT";
export var footer__sociallink = "Footer-module--footer__sociallink--wPjQJ";
export var footer__sociallinkitem = "Footer-module--footer__sociallinkitem--rUMOI";
export var footer__sociallinks = "Footer-module--footer__sociallinks--ORrrf";
export var slideIn = "Footer-module--slideIn--z24qE";
export var slideInMobileNav = "Footer-module--slideInMobileNav--qgPlP";
export var slideInNav = "Footer-module--slideInNav--xvH6+";
export var slideInTop = "Footer-module--slideInTop--TKAzE";
export var slideOut = "Footer-module--slideOut--I1Cij";
export var slideOutTop = "Footer-module--slideOutTop--NzGLu";