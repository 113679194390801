import React from 'react';
import cx from 'classnames';
import { CONTENT_DETAIL, HOMEPAGE, BLANK } from '@constants';
import { BasicRichText } from '@molecules';
import { useSourcedLink } from '@hooks';
import { capitalizeFirstLetter } from '@utils';

import Arrow from '../../../assets/arrow.inline.svg';
import * as styles from './TakeActionSection.module.scss';

const TakeActionSection = (props) => {
  const {
    sectionTheme, sectionTitle, previousSectionDonation, previousSectionSignup,
    firstSection, summaryContent, contentReferences, pageType,
  } = props;
  const themeAppend = capitalizeFirstLetter(sectionTheme.replace(/\s/g, ''));
  const [makeSourcedLink] = useSourcedLink();

  return (
    <section
      className={cx(styles.takeactionsection,
        styles[`takeactionsection${themeAppend}`], {
          [styles.takeactionsectionFirstSection]: firstSection,
          [styles.takeactionsectionPreviousDonation]: (
            previousSectionDonation || previousSectionSignup),
          [styles.takeactionsectionContentDetail]: pageType === CONTENT_DETAIL,
          [styles.takeactionsectionHomepage]: pageType === HOMEPAGE,
        })}
    >
      <div className={styles.takeactionsection__wrapper}>
        <div
          className={cx(styles.takeactionsection__content, {
            [styles.takeactionsection__contentFirstSection]: firstSection,
          })}
        >
          {sectionTitle && (
          <h2 className={cx(styles.takeactionsection__heading)}>
            {sectionTitle}
          </h2>
          )}
          {summaryContent && (
          <BasicRichText content={summaryContent} />
          )}
          {contentReferences && (
            <ul className={styles.takeactionsection__listing}>
              {contentReferences.map((card) => {
                const {
                  id, title, externalLink, internalLink,
                } = card;
                const { path: cardpath } = internalLink || {};
                const cardLinkProps = {
                  href: makeSourcedLink(cardpath || externalLink),
                };
                if (externalLink) {
                  cardLinkProps.target = BLANK;
                }
                return (
                  <li className={styles.takeactionsection__item} key={id}>
                    <a className={styles.takeactionsection__cardlink} {...cardLinkProps}>
                      <span>{title}</span>
                      <Arrow />
                    </a>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    </section>
  );
};

export default TakeActionSection;
