// extracted by mini-css-extract-plugin
export var fadeInAnimation = "NewsPagination-module--fadeInAnimation--lS64q";
export var fadeOutAnimation = "NewsPagination-module--fadeOutAnimation--WzjXY";
export var newspagination = "NewsPagination-module--newspagination--RSJZJ";
export var newspagination__button = "NewsPagination-module--newspagination__button--fIF4q";
export var newspagination__buttonNext = "NewsPagination-module--newspagination__button--next--OLMqF";
export var newspagination__buttonPrevious = "NewsPagination-module--newspagination__button--previous--kWF4S";
export var newspagination__item = "NewsPagination-module--newspagination__item--tthx3";
export var newspagination__itemSelectable = "NewsPagination-module--newspagination__item--selectable--N3ZaE";
export var newspagination__itemSelected = "NewsPagination-module--newspagination__item--selected--8uPWu";
export var slideIn = "NewsPagination-module--slideIn--kgnq2";
export var slideInMobileNav = "NewsPagination-module--slideInMobileNav--q91jQ";
export var slideInNav = "NewsPagination-module--slideInNav--zJ-uz";
export var slideInTop = "NewsPagination-module--slideInTop--AZDUu";
export var slideOut = "NewsPagination-module--slideOut--8zcVw";
export var slideOutTop = "NewsPagination-module--slideOutTop--9Un8v";