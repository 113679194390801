// extracted by mini-css-extract-plugin
export var fadeInAnimation = "ModalVideo-module--fadeInAnimation--2FGVo";
export var fadeOutAnimation = "ModalVideo-module--fadeOutAnimation--wQmte";
export var modalvideo__close = "ModalVideo-module--modalvideo__close--0aaxU";
export var modalvideo__container = "ModalVideo-module--modalvideo__container--KMes3";
export var modalvideo__containerClosing = "ModalVideo-module--modalvideo__container--closing--xoCyQ";
export var modalvideo__player = "ModalVideo-module--modalvideo__player--P8VSq";
export var modalvideo__playerwrap = "ModalVideo-module--modalvideo__playerwrap--ATdXq";
export var slideIn = "ModalVideo-module--slideIn--lhjLY";
export var slideInMobileNav = "ModalVideo-module--slideInMobileNav--PqMuJ";
export var slideInNav = "ModalVideo-module--slideInNav--Lvnvc";
export var slideInTop = "ModalVideo-module--slideInTop--nncG6";
export var slideOut = "ModalVideo-module--slideOut--huVlD";
export var slideOutTop = "ModalVideo-module--slideOutTop--fz9I-";