import React, { useState } from 'react';
import cx from 'classnames';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { useInView } from 'react-intersection-observer';
import { slugify } from '@utils';
import Arrow from '../../../assets/arrow.inline.svg';
import Chevron from '../../../assets/chevron.inline.svg';

import * as styles from './TableOfContents.module.scss';

const TableOfContents = (props) => {
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [tocRef, tocInView] = useInView({
    triggerOnce: false,
    rootMargin: '-76px 0px 0px 0px',
    initialInView: true,
    threshold: 0,
  });

  const {
    issues,
    tocTitle,
    tocStickyTitle,
    location,
  } = props;

  const { pathname } = location;

  return (
    <React.Fragment>
      <section
        className={cx(styles.tableofcontents)}
        ref={tocRef}
      >
        <div className={styles.tableofcontents__wrapper} id="feat-plan">
          {tocTitle && (
            <h2 className={cx(styles.tableofcontents__heading)}>
              {tocTitle}
            </h2>
          )}
          <ul
            className={cx(styles.tableofcontents__items)}
          >
            {issues && issues.map((issue) => {
              const { id, shortTitle, description } = issue;
              return (
                <li key={`${id}-toc`}>
                  <AnchorLink
                    className={cx(styles.tableofcontents__cta)}
                    to={`${pathname}#${slugify(shortTitle)}`}
                  >
                    {shortTitle}
                    <Arrow />
                  </AnchorLink>
                  {description && (
                    <p>{description.description}</p>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </section>
      <section
        className={cx(styles.stickytableofcontents, {
          [styles.stickytableofcontentsSticky]: !tocInView,
        })}
      >
        <div className={cx(styles.stickytableofcontents__wrapper)}>
          <h2
            id="feat-plan"
            className={cx(styles.stickytableofcontents__heading, {
              [styles.stickytableofcontents__headingClosed]: !accordionOpen,
            })}
          >
            <button
              type="button"
              aria-expanded={accordionOpen}
              aria-controls="toc-items"
              onClick={() => setAccordionOpen(!accordionOpen)}
            >
              {tocStickyTitle || 'Table of Contents'}
              <Chevron />
            </button>
          </h2>
          <ul
            id="toc-items"
            role="region"
            aria-labelledby="feat-plan"
            className={cx(styles.stickytableofcontents__issues)}
            hidden={!accordionOpen}
          >
            {issues && issues.map((issue) => {
              const { id, shortTitle } = issue;
              return (
                <li key={id} className={cx(styles.stickytableofcontents__issue)}>
                  <a
                    href={`${pathname}#${slugify(shortTitle)}`}
                    onClick={() => setAccordionOpen(false)}
                  >
                    {shortTitle}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </section>
    </React.Fragment>
  );
};

export default TableOfContents;
