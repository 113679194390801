// extracted by mini-css-extract-plugin
export var fadeInAnimation = "TableOfContents-module--fadeInAnimation--EdnEO";
export var fadeOutAnimation = "TableOfContents-module--fadeOutAnimation--GQqpL";
export var slideIn = "TableOfContents-module--slideIn--MkoPb";
export var slideInMobileNav = "TableOfContents-module--slideInMobileNav--4Xfxe";
export var slideInNav = "TableOfContents-module--slideInNav--YhSzp";
export var slideInTop = "TableOfContents-module--slideInTop--UJH0B";
export var slideOut = "TableOfContents-module--slideOut--UWrNR";
export var slideOutTop = "TableOfContents-module--slideOutTop--rs8-z";
export var stickytableofcontents = "TableOfContents-module--stickytableofcontents--Mlr6k";
export var stickytableofcontentsSticky = "TableOfContents-module--stickytableofcontents--sticky--p7TNP";
export var stickytableofcontents__heading = "TableOfContents-module--stickytableofcontents__heading--+S-4h";
export var stickytableofcontents__headingClosed = "TableOfContents-module--stickytableofcontents__heading--closed--4tRmr";
export var stickytableofcontents__issue = "TableOfContents-module--stickytableofcontents__issue--vc-kN";
export var stickytableofcontents__issues = "TableOfContents-module--stickytableofcontents__issues--MzYWb";
export var stickytableofcontents__wrapper = "TableOfContents-module--stickytableofcontents__wrapper--uznM0";
export var tableofcontents = "TableOfContents-module--tableofcontents--gxY++";
export var tableofcontents__cta = "TableOfContents-module--tableofcontents__cta--380qW";
export var tableofcontents__heading = "TableOfContents-module--tableofcontents__heading--hTlHp";
export var tableofcontents__items = "TableOfContents-module--tableofcontents__items---oZfk";
export var tableofcontents__wrapper = "TableOfContents-module--tableofcontents__wrapper--4lQX3";