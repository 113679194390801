// extracted by mini-css-extract-plugin
export var fadeInAnimation = "NewsItem-module--fadeInAnimation--X2SUV";
export var fadeOutAnimation = "NewsItem-module--fadeOutAnimation--54aId";
export var newsitem = "NewsItem-module--newsitem--yTuNQ";
export var newsitem__content = "NewsItem-module--newsitem__content--7l5ij";
export var newsitem__dash = "NewsItem-module--newsitem__dash--e41-9";
export var newsitem__eyebrow = "NewsItem-module--newsitem__eyebrow--x1qs2";
export var newsitem__heading = "NewsItem-module--newsitem__heading--XK3Ro";
export var newsitem__meta = "NewsItem-module--newsitem__meta--L956f";
export var newsitem__summary = "NewsItem-module--newsitem__summary--uaIU6";
export var newsitem__title = "NewsItem-module--newsitem__title--JC0Pa";
export var slideIn = "NewsItem-module--slideIn--Wa4PB";
export var slideInMobileNav = "NewsItem-module--slideInMobileNav--57-IK";
export var slideInNav = "NewsItem-module--slideInNav--LwoCf";
export var slideInTop = "NewsItem-module--slideInTop--us9yL";
export var slideOut = "NewsItem-module--slideOut--dLdp4";
export var slideOutTop = "NewsItem-module--slideOutTop--uFmcu";