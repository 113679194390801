// extracted by mini-css-extract-plugin
export var fadeInAnimation = "ImageCollage-module--fadeInAnimation--+Xf4D";
export var fadeOutAnimation = "ImageCollage-module--fadeOutAnimation--pCnk6";
export var imagecollage = "ImageCollage-module--imagecollage--enFOJ";
export var imagecollage1Col = "ImageCollage-module--imagecollage--1Col--NJX-v";
export var imagecollage2Col = "ImageCollage-module--imagecollage--2Col--rjn6C";
export var imagecollage__caption = "ImageCollage-module--imagecollage__caption--gm9wo";
export var imagecollage__caption1Col = "ImageCollage-module--imagecollage__caption--1Col--J06HW";
export var imagecollage__imagewrap = "ImageCollage-module--imagecollage__imagewrap--Vjhtj";
export var imagecollage__imagewrap1Col = "ImageCollage-module--imagecollage__imagewrap--1Col--BQuJl";
export var imagecollage__imagewrap2Col = "ImageCollage-module--imagecollage__imagewrap--2Col--IPtOj";
export var slideIn = "ImageCollage-module--slideIn--l927G";
export var slideInMobileNav = "ImageCollage-module--slideInMobileNav--YzTcs";
export var slideInNav = "ImageCollage-module--slideInNav--ZjSSt";
export var slideInTop = "ImageCollage-module--slideInTop--2WlPp";
export var slideOut = "ImageCollage-module--slideOut--iyYz+";
export var slideOutTop = "ImageCollage-module--slideOutTop--P7Gep";