// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== 'undefined';

export default function setSearchParams(location) {
  let newSearch = location && location.search;
  if (isBrowser && location && location.search) {
    const params = (new URL(location.href)).searchParams;

    const marketSource = params.get('ms');
    const refcode = params.get('refcode');
    if (marketSource && !refcode) {
      newSearch = `${location.search}&refcode=${marketSource}`;
    } else if (!marketSource && refcode) {
      newSearch = `${location.search}&ms=${refcode}`;
    }
    if (newSearch !== location.search && window) {
      window.history.replaceState(null, null, newSearch);
    }
  }

  return newSearch;
}
