import React from 'react';

const defaultState = {
  submitted: {},
  toggleSubmitted: () => {},
};

const FormContext = React.createContext(defaultState);

class FormProvider extends React.Component {
  constructor() {
    super();

    this.state = {
      submitted: {},
    };

    this.toggleSubmitted = (newState) => {
      this.setState({ submitted: newState });
    };
  }

  render() {
    const { children } = this.props;
    const { submitted } = this.state;
    return (
      <FormContext.Provider
        value={{
          submitted,
          toggleSubmitted: this.toggleSubmitted,
        }}
      >
        {children}
      </FormContext.Provider>
    );
  }
}
export default FormContext;
export { FormProvider };
