import React from 'react';
import cx from 'classnames';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { capitalizeFirstLetter } from '@utils';

import * as styles from '../../molecules/BasicRichText/BasicRichText.module.scss';
import * as abstyles from './AlertBar.module.scss';
import Quotes from '../../../assets/quotes.inline.svg';

const options = ({
  color, size,
}) => ({
  renderText: (text) => (
    text.replace(/\u2028/g, '').split('\n').map((t, i) => {
      if (i > 0) {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={`${t}-${i}`}>
            <br />
            {t}
          </React.Fragment>
        );
      }
      return t;
    })
  ),
  renderNode: {
    [BLOCKS.HEADING_1]: () => (
      <React.Fragment />
    ),
    [BLOCKS.HEADING_2]: () => (
      <React.Fragment />
    ),
    [BLOCKS.HEADING_3]: () => (
      <React.Fragment />
    ),
    [BLOCKS.HEADING_4]: () => (
      <React.Fragment />
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <span
        className={cx(styles.basicRichText__paragraph,
          abstyles.alertbar__para, {
            [styles[`basicRichText__paragraph${capitalizeFirstLetter(color)}`]]: color,
            [styles[`basicRichText__paragraph${capitalizeFirstLetter(size)}`]]: size,
          })}
        style={{ display: 'block' }}
      >
        {children}
      </span>
    ),
    [BLOCKS.QUOTE]: (node, children) => {
      let citation;
      return (
        <div className={styles.basicRichText__figureWrap}>
          <figure className={styles.basicRichText__figure}>
            <blockquote className={styles.basicRichText__blockquote}>
              <Quotes />
              {children && children.map((child) => {
                if (child.props) {
                  const quoteChild = child.props.children && child.props.children[0]
                  && child.props.children[0][0];
                  const hasCitation = quoteChild && typeof quoteChild === 'string' && quoteChild.startsWith('[[CITE]]');
                  if (hasCitation) {
                    citation = [quoteChild.replace('[[CITE]]', ''), child.props.children[1] && child.props.children.slice(1)];
                    return (
                      <React.Fragment />
                    );
                  }
                  return child;
                }
                return null;
              })}
            </blockquote>
            {citation && (
            <figcaption className={styles.basicRichText__caption}>
              {citation.map((cite) => cite)}
            </figcaption>
            )}
          </figure>
        </div>
      );
    },
    [BLOCKS.EMBEDDED_ENTRY]: () => (
      <React.Fragment />
    ),
    [BLOCKS.UL_LIST]: (node, children) => (
      <ul
        className={cx(styles.basicRichText__unorderedList, {
          [styles[`basicRichText__unorderedList${capitalizeFirstLetter(color)}`]]: color,
          [styles[`basicRichText__unorderedList${capitalizeFirstLetter(size)}`]]: size,
        })}
      >
        {children}
      </ul>
    ),
    [BLOCKS.OL_LIST]: (node, children) => (
      <ol
        className={cx(styles.basicRichText__orderedList, {
          [styles[`basicRichText__orderedList${capitalizeFirstLetter(color)}`]]: color,
          [styles[`basicRichText__orderedList${capitalizeFirstLetter(size)}`]]: size,
        })}
      >
        {children}
      </ol>
    ),
    [INLINES.HYPERLINK]: () => (
      <React.Fragment />
    ),
    [INLINES.ENTRY_HYPERLINK]: () => (
      <React.Fragment />
    ),
    [INLINES.ASSET_HYPERLINK]: () => (
      <React.Fragment />
    ),
  },
});

export default options;
