import React from 'react';
import cx from 'classnames';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import {
  MEDIUM, SINGLE_COL, LC_BLACK,
} from '@constants';
import { Cta } from '@atoms';
import { BasicRichText } from '@molecules';
import { slugify } from '@utils';
import Arrow from '../../../assets/arrow.inline.svg';

import * as styles from './Issue.module.scss';

const Issue = (props) => {
  const {
    id, summary, longTitle, location, mobileDisclaimer, last,
    donationDisclaimer, returnTop, fullPlanLink, shortTitle,
  } = props;
  const { label, internalPageLink } = fullPlanLink || {};
  const { path } = (internalPageLink) || {};

  return (
    <section
      className={cx(styles.issue, {
        [styles.issueLast]: last,
      })}
      key={`${id}--section`}
      id={slugify(shortTitle)}
    >
      <div className={cx(styles.issue__wrapper)}>
        <div className={cx(styles.issue__title)}>
          <h2 className={cx(styles.issue__heading)}>{longTitle}</h2>
          <AnchorLink className={cx(styles.issue__cta)} to="#feat-plan">
            {returnTop || 'Return to top'}
            <Arrow />
          </AnchorLink>
        </div>
        {summary && (
          <BasicRichText
            content={summary}
            size={MEDIUM}
            textWidth={SINGLE_COL}
            color={LC_BLACK}
            location={location}
            mobileDisclaimer={mobileDisclaimer}
            donationDisclaimer={donationDisclaimer}
          />
        )}
        {label && path && (
          <p className={cx(styles.issue__plan)}>
            <Cta href={path}>{label}</Cta>
          </p>
        )}
      </div>
    </section>
  );
};

export default Issue;
