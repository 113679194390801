// extracted by mini-css-extract-plugin
export var fadeInAnimation = "TakeActionSection-module--fadeInAnimation--g9Slf";
export var fadeOutAnimation = "TakeActionSection-module--fadeOutAnimation--PXnBM";
export var slideIn = "TakeActionSection-module--slideIn--N48ns";
export var slideInMobileNav = "TakeActionSection-module--slideInMobileNav--zgINt";
export var slideInNav = "TakeActionSection-module--slideInNav--d9yxa";
export var slideInTop = "TakeActionSection-module--slideInTop--ZREfm";
export var slideOut = "TakeActionSection-module--slideOut--gI5LD";
export var slideOutTop = "TakeActionSection-module--slideOutTop---gLlf";
export var takeactionsection = "TakeActionSection-module--takeactionsection--C+teo";
export var takeactionsectionContentDetail = "TakeActionSection-module--takeactionsection--contentDetail--TsIPL";
export var takeactionsectionDarkBlue = "TakeActionSection-module--takeactionsection--darkBlue--9jTWU";
export var takeactionsectionFirstSection = "TakeActionSection-module--takeactionsection--firstSection--gdtcj";
export var takeactionsectionHomepage = "TakeActionSection-module--takeactionsection--homepage--NHDXd";
export var takeactionsectionLightBlue = "TakeActionSection-module--takeactionsection--lightBlue--GDBa1";
export var takeactionsectionPreviousDonation = "TakeActionSection-module--takeactionsection--previousDonation--lRyqd";
export var takeactionsectionWhite = "TakeActionSection-module--takeactionsection--white--9tlF4";
export var takeactionsection__carddetails = "TakeActionSection-module--takeactionsection__carddetails--U16BP";
export var takeactionsection__cardlink = "TakeActionSection-module--takeactionsection__cardlink--TTbxe";
export var takeactionsection__content = "TakeActionSection-module--takeactionsection__content--QsJeJ";
export var takeactionsection__contentFirstSection = "TakeActionSection-module--takeactionsection__content--firstSection--H3peY";
export var takeactionsection__ctawrapper = "TakeActionSection-module--takeactionsection__ctawrapper--mugqK";
export var takeactionsection__heading = "TakeActionSection-module--takeactionsection__heading--3w6c-";
export var takeactionsection__item = "TakeActionSection-module--takeactionsection__item--gJtTu";
export var takeactionsection__listing = "TakeActionSection-module--takeactionsection__listing--8E3Pr";
export var takeactionsection__wrapper = "TakeActionSection-module--takeactionsection__wrapper--SMWKD";