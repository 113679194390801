import React from 'react';
import cx from 'classnames';
import {
  LC_WHITE, LEFT, BLANK, XSMALL, NO_OPENER,
} from '@constants';
import { useSourcedLink } from '@hooks';
import { BasicRichText } from '@molecules';
import { appendQueryString, getExpressLink } from '@utils';

import * as styles from './HeroDonationAsk.module.scss';

const HeroDonationAsk = (props) => {
  const {
    desktopDonationButtons, mobileDonationButtons, donationLink, header, intro, donationDisclaimer,
  } = props;
  const buttonCount = desktopDonationButtons ? desktopDonationButtons.length : 0;
  const mobButtonCount = mobileDonationButtons ? mobileDonationButtons.length : 0;

  const [makeSourcedLink] = useSourcedLink();

  return (
    <div
      className={cx(styles.herodonationask)}
    >
      <div
        className={cx(styles.herodonationask__wrapper)}
      >
        <div
          className={cx(styles.herodonationask__content)}
        >
          {header && (
            <h2 className={cx(styles.herodonationask__heading)}>
              {header}
            </h2>
          )}
          {intro && (
            <BasicRichText content={intro} color={LC_WHITE} />
          )}
        </div>
        <div className={styles.herodonationask__ask}>
          {desktopDonationButtons && (
            <ul className={styles.herodonationask__buttons}>
              {desktopDonationButtons.map((amount) => {
                const expLink = getExpressLink({ donationLink, amount });
                return (
                  <li
                    className={cx(styles.herodonationask__button, styles[`herodonationask__button${buttonCount}`])}
                    key={amount.key}
                  >
                    <a
                      href={makeSourcedLink(appendQueryString(expLink, `amount=${amount.value}`))}
                      target={BLANK}
                      rel={NO_OPENER}
                    >
                      {amount.key}
                    </a>
                  </li>
                );
              })}
            </ul>
          )}
          {mobileDonationButtons && donationLink && (
            <ul className={cx(styles.herodonationask__buttons,
              styles.herodonationask__buttonsMobile)}
            >
              {mobileDonationButtons.map((amount) => {
                const expLink = getExpressLink({ donationLink, amount });
                return (
                  <li
                    className={cx(styles.herodonationask__button, styles[`herodonationask__button${mobButtonCount}`])}
                    key={`${amount.key}-mobile`}
                  >
                    <a
                      href={makeSourcedLink(appendQueryString(expLink, `amount=${amount.value}`))}
                      target={BLANK}
                      rel={NO_OPENER}
                    >
                      {amount.key}
                    </a>
                  </li>
                );
              })}
            </ul>
          )}
          {(donationDisclaimer) && (
            <BasicRichText
              content={donationDisclaimer}
              size={XSMALL}
              color={LC_WHITE}
              align={LEFT}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default HeroDonationAsk;
