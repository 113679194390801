// extracted by mini-css-extract-plugin
export var fadeInAnimation = "Issue-module--fadeInAnimation--2sDot";
export var fadeOutAnimation = "Issue-module--fadeOutAnimation--QFuhv";
export var issue = "Issue-module--issue--6ZkXg";
export var issueLast = "Issue-module--issue--last--Hj+eB";
export var issue__cta = "Issue-module--issue__cta---y+o2";
export var issue__heading = "Issue-module--issue__heading--qw0pU";
export var issue__items = "Issue-module--issue__items--Xw-xi";
export var issue__plan = "Issue-module--issue__plan--hUuAn";
export var issue__title = "Issue-module--issue__title--cRY7o";
export var issue__wrapper = "Issue-module--issue__wrapper--ZjxcT";
export var slideIn = "Issue-module--slideIn--BAcfG";
export var slideInMobileNav = "Issue-module--slideInMobileNav--U-NfY";
export var slideInNav = "Issue-module--slideInNav--+39kX";
export var slideInTop = "Issue-module--slideInTop--VV4WO";
export var slideOut = "Issue-module--slideOut--HzFOz";
export var slideOutTop = "Issue-module--slideOutTop--oNGRw";