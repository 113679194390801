// extracted by mini-css-extract-plugin
export var fadeInAnimation = "NewsSection-module--fadeInAnimation--oSbDS";
export var fadeOutAnimation = "NewsSection-module--fadeOutAnimation--8SWnM";
export var newssection = "NewsSection-module--newssection--Alv3M";
export var newssectionDarkBlue = "NewsSection-module--newssection--darkBlue--mClvf";
export var newssectionFirstSection = "NewsSection-module--newssection--firstSection--qfB5v";
export var newssectionLightBlue = "NewsSection-module--newssection--lightBlue--GkKMT";
export var newssectionPreviousDonation = "NewsSection-module--newssection--previousDonation--Sk1Q5";
export var newssectionWhite = "NewsSection-module--newssection--white--t0ofI";
export var newssection__content = "NewsSection-module--newssection__content--F8uZ4";
export var newssection__contentFirstSection = "NewsSection-module--newssection__content--firstSection--XEH5X";
export var newssection__ctawrapper = "NewsSection-module--newssection__ctawrapper--UNHm4";
export var newssection__heading = "NewsSection-module--newssection__heading--nKr63";
export var newssection__item = "NewsSection-module--newssection__item--81gfa";
export var newssection__listing = "NewsSection-module--newssection__listing--VuiHR";
export var newssection__newsdetails = "NewsSection-module--newssection__newsdetails--FxGMp";
export var newssection__newsheading = "NewsSection-module--newssection__newsheading--pA8yj";
export var newssection__newslink = "NewsSection-module--newssection__newslink--T0Vet";
export var newssection__wrapper = "NewsSection-module--newssection__wrapper--D7DY3";
export var slideIn = "NewsSection-module--slideIn--VYSxX";
export var slideInMobileNav = "NewsSection-module--slideInMobileNav--VXu07";
export var slideInNav = "NewsSection-module--slideInNav--sAIqh";
export var slideInTop = "NewsSection-module--slideInTop--J2h+Z";
export var slideOut = "NewsSection-module--slideOut--KoySL";
export var slideOutTop = "NewsSection-module--slideOutTop--C3qtX";