// extracted by mini-css-extract-plugin
export var donorbar = "DonorBar-module--donorbar--7kAJB";
export var donorbarClosing = "DonorBar-module--donorbar--closing--eI1AJ";
export var donorbarOpening = "DonorBar-module--donorbar--opening--42HnG";
export var donorbar__button = "DonorBar-module--donorbar__button--W3aWY";
export var donorbar__button1 = "DonorBar-module--donorbar__button--1--1Tyn6";
export var donorbar__button2 = "DonorBar-module--donorbar__button--2--cvvNS";
export var donorbar__button3 = "DonorBar-module--donorbar__button--3--bzJ6P";
export var donorbar__button4 = "DonorBar-module--donorbar__button--4--6YWWO";
export var donorbar__button5 = "DonorBar-module--donorbar__button--5--tgo1a";
export var donorbar__button6 = "DonorBar-module--donorbar__button--6--FsnHe";
export var donorbar__button7 = "DonorBar-module--donorbar__button--7--IJB8+";
export var donorbar__button8 = "DonorBar-module--donorbar__button--8--UjQ+B";
export var donorbar__buttons = "DonorBar-module--donorbar__buttons--AyVQm";
export var donorbar__buttonsMobile = "DonorBar-module--donorbar__buttons--mobile--L8hmx";
export var donorbar__closebutton = "DonorBar-module--donorbar__closebutton--7tW0P";
export var donorbar__content = "DonorBar-module--donorbar__content--jSaeZ";
export var donorbar__header = "DonorBar-module--donorbar__header--wyyZ0";
export var donorbar__info = "DonorBar-module--donorbar__info--i3c9O";
export var donorbar__intro = "DonorBar-module--donorbar__intro--G7uQ1";
export var donorbar__wrapper = "DonorBar-module--donorbar__wrapper--tPezE";
export var fadeInAnimation = "DonorBar-module--fadeInAnimation--6KMuL";
export var fadeOutAnimation = "DonorBar-module--fadeOutAnimation--nEtYh";
export var slideIn = "DonorBar-module--slideIn--sTQJd";
export var slideInMobileNav = "DonorBar-module--slideInMobileNav--6m3Zm";
export var slideInNav = "DonorBar-module--slideInNav--jUES3";
export var slideInTop = "DonorBar-module--slideInTop--pu1Ox";
export var slideOut = "DonorBar-module--slideOut--EOtCn";
export var slideOutTop = "DonorBar-module--slideOutTop--1QDQx";