import React, { useState, useContext } from 'react';
import { useInView } from 'react-intersection-observer';
import cx from 'classnames';
import AnimateHeight from 'react-animate-height';
import { DARK, TRANSPARENT, BLANK } from '@constants';
import { useSourcedLink } from '@hooks';
import { getKeyValue } from '@utils';

import ModalContext from '../../../context/ModalContext';
import LogoAccent from '../../../assets/logoaccent.inline.svg';
import Logo from '../../../assets/smscdlogo.inline.svg';
import Chevron from '../../../assets/chevron.inline.svg';
import * as styles from './Navigation.module.scss';

const SubNavigation = ({
  nestedNavItems,
  linkProps,
  navLabel,
  mobile,
  isLast,
  sticky,
  hasSubNav,
  id
}) => {
  const [expanded, setExpanded] = useState(false);

  const controlSubNav = (e, mobile) => {
    if (e.key === 'Enter' || mobile) {
      setExpanded(!expanded);
    }
  };

  const expandSubNav = (mobile) => {
    if (!mobile && !expanded) {
      setExpanded(true);
    }
  };

  const collapseSubNav = () => {
    if (!mobile && expanded) {
      setExpanded(false);
    }
  };

  const [makeSourcedLink] = useSourcedLink();
  return (
    <li
      key={`${id}-${mobile ? 'mobile' : 'desktop'}`}
      className={cx(styles.navigation__item, {
        [styles.navigation__itemCta]: isLast,
        [styles.navigation__itemSticky]: sticky,
        [styles.navigation__itemHasSubNav]: hasSubNav
      })}
      onMouseOver={() => expandSubNav(mobile)}
      onMouseLeave={() => collapseSubNav(mobile)}
      onClick={(e) => (mobile && controlSubNav(e, mobile))}
    >
      <span className={styles.navigation__topsubnav}>
        <span>{navLabel}</span>
        <button
          onKeyPress={(e) => controlSubNav(e)}
          aria-expanded={expanded}
          aria-controls={`subnav--${navLabel}`}
          className={cx(styles.navigation__subbutton, {
            [styles.navigation__subbuttonExpanded]: expanded,
          })}
        >
          <span>show submenu</span>
          <Chevron />
        </button>
      </span>
      <ul
        className={cx(styles.navigation__subnav, {
          [styles.navigation__subnavExpanded]: expanded,
        })}
        id={`subnav--${navLabel}`}
        expanded={expanded}
        role="menu"
      >
        {nestedNavItems.map((subitem) => {
          const { label, externalLink } = subitem;
          const { path } = getKeyValue(subitem, 'internalPageLink') || {};
          const { url } = getKeyValue(subitem, 'internalFileLink.file') || {};
          const linkProps = {
            href: makeSourcedLink(path || url || externalLink),
          };
          if (externalLink) {
            linkProps.target = BLANK;
          }
          return(
            <li
              role="menuitem"
              key={`${label}-dsk`}
              className={cx(styles.navigation__itemSubnav, {
                [styles.navigation__itemSubnavExpanded]: expanded,
              })}
            >
              <a {...linkProps}>{label}</a>
            </li>
          )
        })}
      </ul>
    </li>
  )
}

const Navigation = (props) => {
  const {
    navTheme, homepageLink, animate, navigation, otherLangs, currentLanguage,
  } = props;
  const [mobileExpanded, setMobileExpanded] = useState(false);
  const [makeSourcedLink] = useSourcedLink();
  const { sticky } = useContext(ModalContext);
  const [ref, inView] = useInView({ triggerOnce: true, rootMargin: '0px 0px' });
  const { path: homepagePath } = getKeyValue(homepageLink, 'internalPageLink') || {};
  const navigationLength = navigation && navigation.length;
  const cta = navigation && navigation[navigationLength - 1];
  const { externalLink: ctaexternalLink } = cta || {};
  const { path: ctapath } = getKeyValue(cta, 'internalPageLink') || {};
  const { url: ctaurl } = getKeyValue(cta, 'internalFileLink.file') || {};
  const showLangs = otherLangs && otherLangs.length > 0;
  const ctalinkProps = {
    href: makeSourcedLink(ctapath || ctaurl || ctaexternalLink),
  };

  if (ctaexternalLink) {
    ctalinkProps.target = BLANK;
  }

  return (
    <header
      className={cx(styles.header, {
        [styles.headerCollapsed]: !mobileExpanded,
        [styles.headerExpanded]: mobileExpanded,
        [styles.headerSticky]: sticky,
        [styles.headerStickyLang]: sticky && showLangs,
        [styles.headerDark]: navTheme === DARK,
        [styles.headerTransparent]: navTheme === TRANSPARENT,
      })}
    >
      <nav
        ref={ref}
        className={cx(styles.navigation, {
          [styles.navigationAnimate]: animate,
          [styles.navigationAnimateInview]: inView,
          [styles.navigationDark]: navTheme === DARK,
        })}
      >
        <a
          className={cx(styles.navigation__logo, {
            [styles.navigation__logoSticky]: sticky,
            [styles.navigation__logoStickyLang]: sticky && showLangs,
          })}
          key={homepageLink.id}
          aria-label={homepageLink.label}
          href={makeSourcedLink(homepagePath || '/')}
        >
          {sticky ? <Logo /> : <LogoAccent />}
        </a>
        <div className={styles.navigation__mob}>
          {cta && (
          <a
            className={cx(styles.navigation__cta)}
            {...ctalinkProps}
          >
            {cta.label}
          </a>
          )}
          <button
            id="toggle"
            aria-expanded={mobileExpanded}
            className={styles.navigation__hamburger}
            type="button"
            onClick={() => setMobileExpanded(!mobileExpanded)}
          >
            <div
              className={styles.navigation__hamburger}
            >
              <div className={styles.navigation__hamburgerbox}>
                <div
                  className={cx(styles.navigation__hamburgerinner, {
                    [styles.navigation__hamburgerinnerActive]: mobileExpanded,
                  })}
                />
              </div>
            </div>
          </button>
        </div>
        <ul
          className={cx(styles.navigation__list, styles.navigation__listMobile, {
            [styles.navigation__listExpanded]: mobileExpanded,
            [styles.navigation__listCollapsed]: !mobileExpanded,
            [styles.navigation__listSticky]: sticky,
          })}
          id="menu"
          aria-hidden={mobileExpanded}
        >
          <AnimateHeight
            duration={300}
            height={mobileExpanded ? 'auto' : 0}
            style={{ width: '100%' }}
          >
            {navigation && navigation.map((nav, i) => {
              const isLast = navigationLength === (i + 1);
              const { externalLink, nestedNavItems } = nav;
              const { path } = getKeyValue(nav, 'internalPageLink') || {};
              const { url } = getKeyValue(nav, 'internalFileLink.file') || {};
              const hasSubNav = nestedNavItems && nestedNavItems.length > 0;
              const linkProps = {
                href: makeSourcedLink(path || url || externalLink),
              };
              if (externalLink) {
                linkProps.target = BLANK;
              }
              if (hasSubNav) {
                return (
                  <SubNavigation
                    nestedNavItems={nestedNavItems}
                    navLabel={nav.label}
                    mobile={true}
                    isLast={isLast}
                    sticky={sticky}
                    hasSubNav={hasSubNav}
                    id={nav.id}
                    linkProps={linkProps}
                  />
                );
              }
              return (
                <li
                  key={`${nav.id}-mobile`}
                  className={cx(styles.navigation__item, {
                    [styles.navigation__itemCta]: isLast,
                    [styles.navigation__itemSticky]: sticky
                  })}
                >
                  <a {...linkProps}>
                    {nav.label}
                  </a>
                </li>
              );
            })}
            {otherLangs && currentLanguage && (
            <li>
              <ul className={styles.navigation__langlist}>
                <li
                  key={`${currentLanguage.label}-${currentLanguage.label}`}
                  className={cx(styles.navigation__lang, styles.navigation__langActive, {
                    [styles.navigation__langSticky]: sticky,
                  })}
                >
                  <span>{currentLanguage.label}</span>
                </li>
                {otherLangs.map((lang) => (
                  <li
                    className={cx(styles.navigation__lang, {
                      [styles.navigation__langSticky]: sticky,
                    })}
                    key={`${lang.path}-${lang.label}`}
                  >
                    <a href={makeSourcedLink(lang.path)}>
                      <span>{lang.label}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </li>
            )}
          </AnimateHeight>
        </ul>
        <ul
          className={cx(styles.navigation__list, styles.navigation__listDesktop, {
            [styles.navigation__listExpanded]: mobileExpanded,
            [styles.navigation__listCollapsed]: !mobileExpanded,
            [styles.navigation__listSticky]: sticky,
            [styles.navigation__listStickyLang]: sticky && showLangs,
          })}
          id="menudesktop"
        >
          {navigation && navigation.map((nav, i) => {
            const isLast = navigationLength === (i + 1);
            const { externalLink, nestedNavItems } = nav;
            const { path } = getKeyValue(nav, 'internalPageLink') || {};
            const { url } = getKeyValue(nav, 'internalFileLink.file') || {};
            const hasSubNav = nestedNavItems && nestedNavItems.length > 0;
            const linkProps = {
              href: makeSourcedLink(path || url || externalLink),
            };
            if (externalLink) {
              linkProps.target = BLANK;
            }
            if (hasSubNav) {
              return (
                <SubNavigation
                  nestedNavItems={nestedNavItems}
                  mobile={false}
                  navLabel={nav.label}
                  isLast={isLast}
                  sticky={sticky}
                  hasSubNav={hasSubNav}
                  id={nav.id}
                  linkProps={linkProps}
                />
              );
            }
            return (
              <li
                key={`${nav.id}-desktop`}
                className={cx(styles.navigation__item, {
                  [styles.navigation__itemCta]: isLast,
                  [styles.navigation__itemSticky]: sticky
                })}
              >
                <a {...linkProps}>
                  {nav.label}
                </a>
              </li>
            );
          })}
          {otherLangs && currentLanguage && (
            <li className={cx(styles.navigation__langs)}>
              <ul>
                <li
                  className={cx(styles.navigation__lang, styles.navigation__langActive, {
                    [styles.navigation__langSticky]: sticky,
                  })}
                  key={`${currentLanguage.label}-${currentLanguage.label}`}
                >
                  <span>{currentLanguage.label}</span>
                </li>
                {otherLangs.map((lang) => (
                  <li
                    className={cx(styles.navigation__lang, {
                      [styles.navigation__langSticky]: sticky,
                    })}
                    key={`${lang.path}-${lang.label}`}
                  >
                    <a href={makeSourcedLink(lang.path)}>
                      <span>{lang.label}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </li>
          )}
        </ul>
      </nav>
    </header>
  );
};

export default Navigation;
