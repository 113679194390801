import React from 'react';
import cx from 'classnames';
import { Cta } from '@atoms';
import { BLANK, HTTP } from '@constants';
import { BasicRichText } from '@molecules';
import { capitalizeFirstLetter } from '@utils';

import * as styles from './NewsSection.module.scss';

const NewsSection = (props) => {
  const {
    sectionTheme, sectionTitle, readMoreText, internalReadMoreLink, externalReadMoreLink,
    firstSection, summaryContent, contentReferences, previousSectionDonation, previousSectionSignup,
  } = props;
  const themeAppend = capitalizeFirstLetter(sectionTheme.replace(/\s/g, ''));
  const { path } = internalReadMoreLink || {};
  const linkProps = {
    href: path || externalReadMoreLink,
  };
  if (externalReadMoreLink) {
    linkProps.target = BLANK;
  }

  return (
    <section
      className={cx(styles.newssection,
        styles[`newssection${themeAppend}`], {
          [styles.newssectionFirstSection]: firstSection,
          [styles.newssectionPreviousDonation]: (
            previousSectionDonation || previousSectionSignup),
        })}
    >
      <div className={styles.newssection__wrapper}>
        <div
          className={cx(styles.newssection__content, {
            [styles.newssection__contentFirstSection]: firstSection,
          })}
        >
          {sectionTitle && (
          <h2 className={cx(styles.newssection__heading)}>
            {sectionTitle}
          </h2>
          )}
          {summaryContent && (
          <BasicRichText content={summaryContent} />
          )}
          {contentReferences && (
            <ul className={styles.newssection__listing}>
              {contentReferences.map((news) => {
                const {
                  id, title, titleEyebrow, publisher, category, publicationDate, page,
                } = news;
                const { path: newspath } = (page && page[0]) || {};
                if (!newspath) {
                  return null;
                }
                const newsLinkProps = {
                  href: newspath,
                };
                if (newspath && newspath.startsWith(HTTP)) {
                  newsLinkProps.target = BLANK;
                }
                return (
                  <li className={styles.newssection__item} key={id}>
                    <a className={styles.newssection__newslink} {...newsLinkProps}>
                      <span className={styles.newssection__newsheading}>
                        {titleEyebrow ? `${titleEyebrow} ` : ''}
                        {title}
                      </span>
                      <span className={styles.newssection__newsdetails}>
                        {newsLinkProps.target && publisher && (
                          <span>{publisher}</span>
                        )}
                        {!newsLinkProps.target && category && (
                          <span>{category}</span>
                        )}
                        {(((newsLinkProps.target && publisher) || (!newsLinkProps.target && category)) && publicationDate) && ' | '}
                        {publicationDate && (
                          <span>{publicationDate}</span>
                        )}
                      </span>
                    </a>
                  </li>
                );
              })}
            </ul>
          )}
          {linkProps.href && (
          <div className={styles.newssection__ctawrapper}>
            <Cta {...linkProps}>{readMoreText}</Cta>
          </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default NewsSection;
