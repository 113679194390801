import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { BasicRichText } from '@molecules';
import { useSourcedLink } from '@hooks';
import { appendQueryString, getExpressLink } from '@utils';
import {
  BLANK, DISMISS_DONATE_BAR, LC_WHITE, LC_BLACK, LEFT, CENTER, XSMALL, NO_OPENER,
} from '@constants';

import Close from '../../../assets/close.inline.svg';
import * as styles from './DonorBar.module.scss';

const DonorBar = (props) => {
  const [closed, setClosed] = useState(false);
  const [closing, setClosing] = useState(false);
  const [render, setRender] = useState(false);
  const {
    header, intro, donationDisclaimer, donationLink, desktopDonationButtons, mobileDonationButtons,
  } = props;

  const buttonCount = desktopDonationButtons ? desktopDonationButtons.length : 0;
  const mobButtonCount = mobileDonationButtons ? mobileDonationButtons.length : 0;

  const CloseBar = () => {
    if (!closed) {
      setClosing(true);
      setTimeout(() => {
        setClosed(true);
        sessionStorage.setItem(DISMISS_DONATE_BAR, 'true');
      }, 400);
      setTimeout(() => {
        setClosing(false);
      }, 500);
    }
  };

  useEffect(() => {
    setClosed(!!sessionStorage.getItem(DISMISS_DONATE_BAR));
  }, []);

  useEffect(() => {
    const callback = () => {
      if (window.scrollY >= 50 && !render && !closed) {
        setRender(true);
      } else if (window.scrollY < 50 && render) {
        setClosing(true);
        setTimeout(() => {
          setRender(false);
        }, 400);
        setTimeout(() => {
          setClosing(false);
        }, 500);
      }
    };
    window.addEventListener('scroll', callback);
    return () => window.removeEventListener('scroll', callback);
  }, [closed, render]);

  const [makeSourcedLink] = useSourcedLink();

  return (
    <React.Fragment>
      {!closed && render && donationLink ? (
        <nav
          className={cx(styles.donorbar, {
            [styles.donorbarClosing]: closing,
            [styles.donorbarOpening]: !closing,
          })}
        >
          <div className={styles.donorbar__wrapper}>
            <div className={styles.donorbar__content}>
              {header && (
                <h2 className={styles.donorbar__header}>{header}</h2>
              )}
              {intro && (
                <div className={styles.donorbar__intro}>
                  <BasicRichText content={intro} color={LC_WHITE} />
                </div>
              )}
              {mobileDonationButtons && (
                <ul className={cx(styles.donorbar__buttons, styles.donorbar__buttonsMobile)}>
                  {mobileDonationButtons.map((amount) => {
                    const expLink = getExpressLink({ donationLink, amount });
                    return (
                      <li
                        className={cx(styles.donorbar__button, styles[`donorbar__button${mobButtonCount}`])}
                        key={`${amount.key}-mobile`}
                      >
                        <a
                          href={makeSourcedLink(appendQueryString(expLink, `amount=${amount.value}`))}
                          target={BLANK}
                          rel={NO_OPENER}
                        >
                          {amount.key}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
            <div className={styles.donorbar__info}>
              {desktopDonationButtons && (
                <ul className={styles.donorbar__buttons}>
                  {desktopDonationButtons.map((amount) => {
                    const expLink = getExpressLink({ donationLink, amount });
                    return (
                      <li className={cx(styles.donorbar__button, styles[`donorbar__button${buttonCount}`])} key={amount.key}>
                        <a
                          href={makeSourcedLink(appendQueryString(expLink, `amount=${amount.value}`))}
                          target={BLANK}
                          rel={NO_OPENER}
                        >
                          {amount.key}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              )}
              {(donationDisclaimer) && (
                <BasicRichText
                  content={donationDisclaimer}
                  size={XSMALL}
                  color={LC_BLACK}
                  align={CENTER}
                  alignMobile={LEFT}
                />
              )}
            </div>
          </div>
          <button className={styles.donorbar__closebutton} type="button" onClick={CloseBar}>
            <Close />
          </button>
        </nav>
      ) : null}
    </React.Fragment>
  );
};

export default DonorBar;
