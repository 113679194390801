import React from 'react';
import cx from 'classnames';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { PLAN, NEWS } from '@constants';
import { InlineLink } from '@atoms';
import { ImageCollage } from '@molecules';
import {
  FormWrapper, Video, InlineDonationAsk, InlineSignUpAsk, EventListing,
} from '@organisms';
import { getKeyValue, capitalizeFirstLetter } from '@utils';

import * as styles from './BasicRichText.module.scss';
import Quotes from '../../../assets/quotes.inline.svg';

const options = ({
  color, size, pageType, mobileDisclaimer, donationDisclaimer, makeSourcedLink,
}) => ({
  renderText: (text) => (
    text.replace(/\u2028/g, '').split('\n').map((t, i) => {
      if (i > 0) {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={`${t}-${i}`}>
            <br />
            {t}
          </React.Fragment>
        );
      }
      return t;
    })
  ),
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => (
      <h1
        className={cx(styles.basicRichText__heading1, {
          [styles[`basicRichText__heading1${capitalizeFirstLetter(color)}`]]: color,
          [styles[`basicRichText__heading1${capitalizeFirstLetter(size)}`]]: size,
        })}
      >
        {children}
      </h1>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <h2
        className={cx(styles.basicRichText__heading2, {
          [styles[`basicRichText__heading2${capitalizeFirstLetter(color)}`]]: color,
          [styles[`basicRichText__heading2${capitalizeFirstLetter(size)}`]]: size,
        })}
      >
        {children}
      </h2>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <h3
        className={cx(styles.basicRichText__heading3, {
          [styles[`basicRichText__heading3${capitalizeFirstLetter(color)}`]]: color,
          [styles[`basicRichText__heading3${capitalizeFirstLetter(size)}`]]: size,
        })}
      >
        {children}
      </h3>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <h4
        className={cx(styles.basicRichText__heading4, {
          [styles[`basicRichText__heading4${capitalizeFirstLetter(color)}`]]: color,
          [styles[`basicRichText__heading4${capitalizeFirstLetter(size)}`]]: size,
        })}
      >
        {children}
      </h4>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => {
      const { content } = node;
      if (content && content.length === 1 && content[0].value === '') {
        return <React.Fragment />;
      }
      return (
        <p
          className={cx(styles.basicRichText__paragraph, {
            [styles[`basicRichText__paragraph${capitalizeFirstLetter(color)}`]]: color,
            [styles[`basicRichText__paragraph${capitalizeFirstLetter(size)}`]]: size,
          })}
        >
          {children}
        </p>
      );
    },
    [BLOCKS.QUOTE]: (node, children) => {
      let citation;
      return (
        <div className={styles.basicRichText__figureWrap}>
          <figure className={styles.basicRichText__figure}>
            <blockquote className={styles.basicRichText__blockquote}>
              <Quotes />
              {children && children.map((child) => {
                if (child.props) {
                  const quoteChild = child.props.children && child.props.children[0]
                  && child.props.children[0][0];
                  const hasCitation = quoteChild && typeof quoteChild === 'string' && quoteChild.startsWith('[[CITE]]');
                  if (hasCitation) {
                    citation = [quoteChild.replace('[[CITE]]', ''), child.props.children[1] && child.props.children.slice(1)];
                    return (
                      <React.Fragment />
                    );
                  }
                  return child;
                }
                return null;
              })}
            </blockquote>
            {citation && (
            <figcaption className={styles.basicRichText__caption}>
              {citation.map((cite) => cite)}
            </figcaption>
            )}
          </figure>
        </div>
      );
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const { data: { target } } = node;
      const contentType = target && target.internal && target.internal.type;
      const { formType } = target;
      if (contentType === 'ContentfulComponentForm') {
        if (pageType === PLAN || pageType === NEWS) {
          return (
            <div className={styles.basicRichText__signupaskwrapper}>
              <InlineSignUpAsk
                {...target}
                formTheme={(pageType === 'contentDetail' || pageType === 'Plan') ? 'light' : 'dark'}
                mobileDisclaimer={mobileDisclaimer}
                donationDisclaimer={donationDisclaimer}
                pageType={pageType}
              />
            </div>
          );
        } else if (formType === 'EventListing') {
          return (
            <div className={styles.basicRichText__formwrapper}>
              <EventListing
                {...target}
              />
            </div>
          );
        }
        return (
          <div className={styles.basicRichText__formwrapper}>
            <FormWrapper
              {...target}
              formTheme={(pageType === 'contentDetail' || pageType === 'Plan') ? 'light' : 'dark'}
              mobileDisclaimer={mobileDisclaimer}
              donationDisclaimer={donationDisclaimer}
              pageType={pageType}
            />
          </div>
        );
      }
      if (contentType === 'ContentfulComponentDonationAsk') {
        return (
          <div className={styles.basicRichText__donationwrapper}>
            <InlineDonationAsk
              {...target}
              mobileDisclaimer={mobileDisclaimer}
              donationDisclaimer={donationDisclaimer}
              pageType={pageType}
            />
          </div>
        );
      }
      if (contentType === 'ContentfulComponentMediaEmbed') {
        const { mediaType } = target;
        if (mediaType === 'YouTube') {
          const {
            imageOrCustomThumbnail, mediaUid, mediaTitle, mediaDescription,
          } = target;
          return (
            <div className={styles.basicRichText__videowrapper}>
              <Video
                customThumbnail={imageOrCustomThumbnail}
                videoId={mediaUid}
                title={mediaTitle}
                caption={mediaDescription}
              />
            </div>
          );
        }
      }
      if (contentType === 'ContentfulComponentImageCollage') {
        return (
          <ImageCollage {...target} />
        );
      }

      return <React.Fragment />;
    },
    [BLOCKS.UL_LIST]: (node, children) => (
      <ul
        className={cx(styles.basicRichText__unorderedList, {
          [styles[`basicRichText__unorderedList${capitalizeFirstLetter(color)}`]]: color,
          [styles[`basicRichText__unorderedList${capitalizeFirstLetter(size)}`]]: size,
        })}
      >
        {children}
      </ul>
    ),
    [BLOCKS.OL_LIST]: (node, children) => (
      <ol
        className={cx(styles.basicRichText__orderedList, {
          [styles[`basicRichText__orderedList${capitalizeFirstLetter(color)}`]]: color,
          [styles[`basicRichText__orderedList${capitalizeFirstLetter(size)}`]]: size,
        })}
      >
        {children}
      </ol>
    ),
    [INLINES.HYPERLINK]: (node, children) => {
      const { data: { uri: href } } = node;
      const anchorProps = {
        href: makeSourcedLink(href),
        color,
      };

      if (href && href.startsWith('http')) {
        anchorProps.target = '_blank';
      }

      return (
        <InlineLink {...anchorProps}>
          {children}
        </InlineLink>
      );
    },
    [INLINES.ENTRY_HYPERLINK]: (node, children) => {
      const { path, from, to } = getKeyValue(node, 'data.target') || {};
      const anchorProps = {
        color,
      };
      if (path) {
        anchorProps.href = makeSourcedLink(path);
      } else if (from) {
        anchorProps.href = makeSourcedLink(from);

        if (to && to.startsWith('http')) {
          anchorProps.target = '_blank';
        }
      }
      if (anchorProps.href) {
        return (
          <InlineLink {...anchorProps}>
            {children}
          </InlineLink>
        );
      }
      return null;
    },
    [INLINES.ASSET_HYPERLINK]: (node, children) => {
      const { file } = getKeyValue(node, 'data.target') || {};
      const { url: href } = file || {};
      const anchorProps = {
        href: makeSourcedLink(href),
        color,
      };

      if (href && href.startsWith('http')) {
        anchorProps.target = '_blank';
      }

      return (
        <InlineLink {...anchorProps}>
          {children}
        </InlineLink>
      );
    },
  },
});

export default options;
