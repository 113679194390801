import React from 'react';
import cx from 'classnames';
import Img from 'gatsby-image/withIEPolyfill';
import { Cta } from '@atoms';
import { BLANK } from '@constants';
import { BasicRichText } from '@molecules';
import { capitalizeFirstLetter } from '@utils';

import * as styles from './EndorsementSection.module.scss';

const EndorsementSection = (props) => {
  const {
    sectionTheme, sectionTitle, readMoreText, internalReadMoreLink, externalReadMoreLink,
    firstSection, summaryContent, contentReferences, previousSectionDonation, previousSectionSignup,
  } = props;
  const themeAppend = capitalizeFirstLetter(sectionTheme.replace(/\s/g, ''));
  const { path } = internalReadMoreLink || {};
  const linkProps = {
    href: path || externalReadMoreLink,
  };
  if (externalReadMoreLink) {
    linkProps.target = BLANK;
  }

  return (
    <section
      className={cx(styles.endorsementsection,
        styles[`endorsementsection${themeAppend}`], {
          [styles.endorsementsectionFirstSection]: firstSection,
          [styles.endorsementsectionPreviousDonation]: (
            previousSectionDonation || previousSectionSignup),
        })}
    >
      <div className={styles.endorsementsection__wrapper}>
        <div
          className={cx(styles.endorsementsection__content, {
            [styles.endorsementsection__contentFirstSection]: firstSection,
          })}
        >
          <div className={cx(styles.endorsementsection__text)}>
            {sectionTitle && (
            <h2 className={cx(styles.endorsementsection__heading)}>
              {sectionTitle}
            </h2>
            )}
            {summaryContent && (
            <BasicRichText content={summaryContent} />
            )}
            {linkProps.href && (
            <div className={styles.endorsementsection__ctawrapper}>
              <Cta {...linkProps}>{readMoreText}</Cta>
            </div>
            )}
          </div>
          {contentReferences && (
            <ul className={styles.endorsementsection__listing}>
              {contentReferences.map((endorsement) => {
                const {
                  id, title, fullName, externalLink, internalPressReleaseLink,
                  headshotlogo, endorsementType,
                } = endorsement;
                const isLogo = endorsementType === 'Organization';
                const { path: endpath } = (internalPressReleaseLink) || {};
                const endLinkProps = {
                  href: endpath || externalLink,
                };
                if (externalLink) {
                  endLinkProps.target = BLANK;
                }
                return (
                  <li className={styles.endorsementsection__item} key={id}>
                    {endLinkProps.href ? (
                      <a
                        className={cx(
                          styles.endorsementsection__endlink,
                          styles.endorsementsection__endlinkLink,
                        )}
                        {...endLinkProps}
                      >
                        <div
                          className={cx(styles.endorsementsection__imagewrap, {
                            [styles.endorsementsection__imagewrapLogo]: isLogo,
                          })}
                        >
                          <Img
                            alt={`${fullName} ${title}`}
                            fluid={headshotlogo.fluid}
                            objectFit={isLogo ? 'contain' : 'cover'}
                            objectPosition="50% 50%"
                            className={cx(styles.endorsementsection__image, {
                              [styles.endorsementsection__imageLogo]: isLogo,
                            })}
                          />
                        </div>
                        <span className={styles.endorsementsection__endheading}>
                          {fullName}
                          {title && (
                            <span>
                              {', '}
                              <br />
                              {title}
                            </span>
                          )}
                        </span>
                      </a>
                    ) : (
                      <span className={styles.endorsementsection__endlink}>
                        <div
                          className={cx(styles.endorsementsection__imagewrap, {
                            [styles.endorsementsection__imagewrapLogo]: isLogo,
                          })}
                        >
                          <Img
                            alt={`${fullName} ${title}`}
                            fluid={headshotlogo.fluid}
                            objectFit={isLogo ? 'contain' : 'cover'}
                            objectPosition="50% 50%"
                            className={cx(styles.endorsementsection__image, {
                              [styles.endorsementsection__imageLogo]: isLogo,
                            })}
                          />
                        </div>
                        <span className={styles.endorsementsection__endheading}>
                          {fullName}
                          {title && (
                            <span>
                              {', '}
                              <br />
                              {title}
                            </span>
                          )}
                        </span>
                      </span>
                    )}
                  </li>
                );
              })}
            </ul>
          )}
          {contentReferences && contentReferences.length > 4 && linkProps.href && (
          <div className={cx(
            styles.endorsementsection__ctawrapper,
            styles.endorsementsection__ctawrapperMobile,
          )}
          >
            <Cta {...linkProps}>{readMoreText}</Cta>
          </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default EndorsementSection;
