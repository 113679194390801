import React from 'react';
import cx from 'classnames';
import { Link } from 'gatsby';
import { BLANK, REL } from '@constants';
import { useSourcedLink } from '@hooks';
import { capitalizeFirstLetter } from '@utils';

import * as styles from './InlineLink.module.scss';

const InlineLink = ({
  href, children, color, ...rest
}) => {
  const [makeSourcedLink] = useSourcedLink();

  if (!href) {
    return <React.Fragment />;
  }

  if (href.startsWith('http') || href.startsWith('//') || href.startsWith('mailto') || href.startsWith('tel')) {
    return (
      <a
        target={BLANK}
        rel={REL}
        href={makeSourcedLink(href)}
        className={cx(styles.inlinelink, {
          [styles[`inlinelink${capitalizeFirstLetter(color)}`]]: color,
        })}
        {...rest}
      >
        {children}
      </a>
    );
  }

  return (
    <Link
      to={makeSourcedLink(href)}
      className={cx(styles.inlinelink, {
        [styles[`inlinelink${capitalizeFirstLetter(color)}`]]: color,
      })}
      {...rest}
    >
      {children}
    </Link>
  );
};

export default InlineLink;
