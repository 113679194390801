// extracted by mini-css-extract-plugin
export var donationsection = "DonationSection-module--donationsection--cm546";
export var donationsectionDarkBlue = "DonationSection-module--donationsection--darkBlue--MBiod";
export var donationsectionFirstSection = "DonationSection-module--donationsection--firstSection--fnXj6";
export var donationsectionLightBlue = "DonationSection-module--donationsection--lightBlue--jx7kq";
export var donationsectionPreviousDonation = "DonationSection-module--donationsection--previousDonation--IknCe";
export var donationsectionWhite = "DonationSection-module--donationsection--white--pKe-V";
export var donationsection__ask = "DonationSection-module--donationsection__ask--71U-w";
export var donationsection__button = "DonationSection-module--donationsection__button--bMHXg";
export var donationsection__buttons = "DonationSection-module--donationsection__buttons--WeTKR";
export var donationsection__buttonsMobile = "DonationSection-module--donationsection__buttons--mobile--3sEgP";
export var donationsection__content = "DonationSection-module--donationsection__content--Kzq08";
export var donationsection__contentFirstSection = "DonationSection-module--donationsection__content--firstSection--tDuwd";
export var donationsection__heading = "DonationSection-module--donationsection__heading--NEhln";
export var donationsection__wrapper = "DonationSection-module--donationsection__wrapper--wHxiJ";
export var donationsection__wrapperDarkBlue = "DonationSection-module--donationsection__wrapper--darkBlue--JBzbP";
export var donationsection__wrapperLightBlue = "DonationSection-module--donationsection__wrapper--lightBlue--GC4eL";
export var donationsection__wrapperWhite = "DonationSection-module--donationsection__wrapper--white--nlP1T";
export var fadeInAnimation = "DonationSection-module--fadeInAnimation--4-gme";
export var fadeOutAnimation = "DonationSection-module--fadeOutAnimation--qaOMJ";
export var slideIn = "DonationSection-module--slideIn--Z8zlK";
export var slideInMobileNav = "DonationSection-module--slideInMobileNav--vKqn+";
export var slideInNav = "DonationSection-module--slideInNav--oKhre";
export var slideInTop = "DonationSection-module--slideInTop---lmf6";
export var slideOut = "DonationSection-module--slideOut--TmuoL";
export var slideOutTop = "DonationSection-module--slideOutTop--avYlq";