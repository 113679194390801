// extracted by mini-css-extract-plugin
export var fadeInAnimation = "InlineLink-module--fadeInAnimation--9-xqe";
export var fadeOutAnimation = "InlineLink-module--fadeOutAnimation--nyfj2";
export var inlinelink = "InlineLink-module--inlinelink--uUnv2";
export var inlinelinkBase = "InlineLink-module--inlinelink--base--pu6Mr";
export var inlinelinkBlack = "InlineLink-module--inlinelink--black--Tf4uz";
export var inlinelinkWhite = "InlineLink-module--inlinelink--white--mXeJ+";
export var slideIn = "InlineLink-module--slideIn--lbi6U";
export var slideInMobileNav = "InlineLink-module--slideInMobileNav--RIaIA";
export var slideInNav = "InlineLink-module--slideInNav--XuYWc";
export var slideInTop = "InlineLink-module--slideInTop--U69W6";
export var slideOut = "InlineLink-module--slideOut--uRSpN";
export var slideOutTop = "InlineLink-module--slideOutTop---if6H";